@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://cdn.linearicons.com/free/1.0.0/icon-font.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/5.8.55/css/materialdesignicons.min.css);
@charset "UTF-8";

@font-face {
  font-family: "linea-ecommerce-10";
  src:url(/static/media/linea-ecommerce-10.fd5a9ac1.eot);
  src:url(/static/media/linea-ecommerce-10.fd5a9ac1.eot?#iefix) format("embedded-opentype"),
    url(/static/media/linea-ecommerce-10.51f1311f.woff) format("woff"),
    url(/static/media/linea-ecommerce-10.dc1f09ac.ttf) format("truetype"),
    url(/static/media/linea-ecommerce-10.0997fc98.svg#linea-ecommerce-10) format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "linea-ecommerce-10" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "linea-ecommerce-10" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ecommerce-bag:before {
  content: "a";
}
.icon-ecommerce-bag-check:before {
  content: "b";
}
.icon-ecommerce-bag-cloud:before {
  content: "c";
}
.icon-ecommerce-bag-download:before {
  content: "d";
}
.icon-ecommerce-bag-minus:before {
  content: "e";
}
.icon-ecommerce-bag-plus:before {
  content: "f";
}
.icon-ecommerce-bag-refresh:before {
  content: "g";
}
.icon-ecommerce-bag-remove:before {
  content: "h";
}
.icon-ecommerce-bag-search:before {
  content: "i";
}
.icon-ecommerce-bag-upload:before {
  content: "j";
}
.icon-ecommerce-banknote:before {
  content: "k";
}
.icon-ecommerce-banknotes:before {
  content: "l";
}
.icon-ecommerce-basket:before {
  content: "m";
}
.icon-ecommerce-basket-check:before {
  content: "n";
}
.icon-ecommerce-basket-cloud:before {
  content: "o";
}
.icon-ecommerce-basket-download:before {
  content: "p";
}
.icon-ecommerce-basket-minus:before {
  content: "q";
}
.icon-ecommerce-basket-plus:before {
  content: "r";
}
.icon-ecommerce-basket-refresh:before {
  content: "s";
}
.icon-ecommerce-basket-remove:before {
  content: "t";
}
.icon-ecommerce-basket-search:before {
  content: "u";
}
.icon-ecommerce-basket-upload:before {
  content: "v";
}
.icon-ecommerce-bath:before {
  content: "w";
}
.icon-ecommerce-cart:before {
  content: "x";
}
.icon-ecommerce-cart-check:before {
  content: "y";
}
.icon-ecommerce-cart-cloud:before {
  content: "z";
}
.icon-ecommerce-cart-content:before {
  content: "A";
}
.icon-ecommerce-cart-download:before {
  content: "B";
}
.icon-ecommerce-cart-minus:before {
  content: "C";
}
.icon-ecommerce-cart-plus:before {
  content: "D";
}
.icon-ecommerce-cart-refresh:before {
  content: "E";
}
.icon-ecommerce-cart-remove:before {
  content: "F";
}
.icon-ecommerce-cart-search:before {
  content: "G";
}
.icon-ecommerce-cart-upload:before {
  content: "H";
}
.icon-ecommerce-cent:before {
  content: "I";
}
.icon-ecommerce-colon:before {
  content: "J";
}
.icon-ecommerce-creditcard:before {
  content: "K";
}
.icon-ecommerce-diamond:before {
  content: "L";
}
.icon-ecommerce-dollar:before {
  content: "M";
}
.icon-ecommerce-euro:before {
  content: "N";
}
.icon-ecommerce-franc:before {
  content: "O";
}
.icon-ecommerce-gift:before {
  content: "P";
}
.icon-ecommerce-graph1:before {
  content: "Q";
}
.icon-ecommerce-graph2:before {
  content: "R";
}
.icon-ecommerce-graph3:before {
  content: "S";
}
.icon-ecommerce-graph-decrease:before {
  content: "T";
}
.icon-ecommerce-graph-increase:before {
  content: "U";
}
.icon-ecommerce-guarani:before {
  content: "V";
}
.icon-ecommerce-kips:before {
  content: "W";
}
.icon-ecommerce-lira:before {
  content: "X";
}
.icon-ecommerce-megaphone:before {
  content: "Y";
}
.icon-ecommerce-money:before {
  content: "Z";
}
.icon-ecommerce-naira:before {
  content: "0";
}
.icon-ecommerce-pesos:before {
  content: "1";
}
.icon-ecommerce-pound:before {
  content: "2";
}
.icon-ecommerce-receipt:before {
  content: "3";
}
.icon-ecommerce-receipt-bath:before {
  content: "4";
}
.icon-ecommerce-receipt-cent:before {
  content: "5";
}
.icon-ecommerce-receipt-dollar:before {
  content: "6";
}
.icon-ecommerce-receipt-euro:before {
  content: "7";
}
.icon-ecommerce-receipt-franc:before {
  content: "8";
}
.icon-ecommerce-receipt-guarani:before {
  content: "9";
}
.icon-ecommerce-receipt-kips:before {
  content: "!";
}
.icon-ecommerce-receipt-lira:before {
  content: "\"";
}
.icon-ecommerce-receipt-naira:before {
  content: "#";
}
.icon-ecommerce-receipt-pesos:before {
  content: "$";
}
.icon-ecommerce-receipt-pound:before {
  content: "%";
}
.icon-ecommerce-receipt-rublo:before {
  content: "&";
}
.icon-ecommerce-receipt-rupee:before {
  content: "'";
}
.icon-ecommerce-receipt-tugrik:before {
  content: "(";
}
.icon-ecommerce-receipt-won:before {
  content: ")";
}
.icon-ecommerce-receipt-yen:before {
  content: "*";
}
.icon-ecommerce-receipt-yen2:before {
  content: "+";
}
.icon-ecommerce-recept-colon:before {
  content: ",";
}
.icon-ecommerce-rublo:before {
  content: "-";
}
.icon-ecommerce-rupee:before {
  content: ".";
}
.icon-ecommerce-safe:before {
  content: "/";
}
.icon-ecommerce-sale:before {
  content: ":";
}
.icon-ecommerce-sales:before {
  content: ";";
}
.icon-ecommerce-ticket:before {
  content: "<";
}
.icon-ecommerce-tugriks:before {
  content: "=";
}
.icon-ecommerce-wallet:before {
  content: ">";
}
.icon-ecommerce-won:before {
  content: "?";
}
.icon-ecommerce-yen:before {
  content: "@";
}
.icon-ecommerce-yen2:before {
  content: "[";
}

@charset "UTF-8";
html {
  min-height: 100%;
  height: 100%;
}

.wr-loader {
  min-height: 400px;
}
.wr-loader .loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.wr-loader img {
  width: 300px;
  position: absolute;
  top: calc(50% - 150px);
  left: calc(50% - 150px);
  opacity: 0.4;
}

.wr-portfolios {
  position: relative;
}
.wr-portfolios .wr-loader {
  min-height: 0;
  min-height: initial;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 99;
}
.wr-portfolios .wr-loader .lds-roller div:after {
  background: #fff;
}

.app-loader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  background: rgba(255, 255, 255, 0.8);
}
.app-loader .loader {
  width: 300px;
  height: 300px;
  position: absolute;
  top: calc(50% - 150px);
  left: calc(50% - 150px);
}

.lds-roller {
  display: inline-block;
  position: absolute;
  width: 64px;
  height: 64px;
  left: calc(50% - 32px);
  top: calc(50% - 15px);
}
.lds-roller div {
  -webkit-animation: lds-roller 1.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-roller 1.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #d7d7d7;
  margin: -3px 0 0 -3px;
}
.lds-roller div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}
.lds-roller div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}
.lds-roller div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}
.lds-roller div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}
.lds-roller div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}
.lds-roller div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}
.lds-roller div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}
.lds-roller div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}

@-webkit-keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
body {
  min-height: calc(100% - 96px);
  overflow-x: hidden;
  font-family: "Open Sans", sans-serif;
  position: relative;
  background: #ebf0f3;
}

a:not([href]):not([tabindex]) {
  color: #000;
  color: initial;
}

.wr-header {
  top: 0;
  left: 0;
  width: 100%;
  position: relative;
  z-index: 99;
}

header#global-header {
  background: #262626;
}
header#global-header .global-bar {
  padding: 0;
}
header#global-header .global-bar .nav-item > a {
  color: #fff;
  padding: 10px 20px;
  font-size: 13px;
  font-weight: 500;
  display: inline-block;
}
header#global-header .global-bar .nav-item > a.active {
  color: #a7c5e9;
}
header#global-header .global-bar .nav-item > a:hover {
  color: #a7c5e9;
  text-decoration: none;
}
header#global-header .nav-user {
  background: black;
}
header#global-header .nav-user > a.nav-link {
  padding: 11px 35px 14px 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
header#global-header .nav-user span {
  display: block;
  max-width: 200px;
}
header#global-header .nav-user img {
  height: 15px;
  position: absolute;
  right: 10px;
  top: 12px;
  color: #222;
}
header#global-header .nav-user .user-name {
  font-size: 13px;
  line-height: 14px;
  font-weight: 700;
  color: #fff;
}
header#global-header .nav-user .user-designation {
  font-size: 12px;
  font-size: 600;
  color: #A4A4A4;
}
header#global-header .nav-user .dropdown-menu {
  background: black;
  min-width: 210px;
}
header#global-header .nav-user .dropdown-menu .dropdown-item a {
  color: #fff;
}
header#global-header .nav-user .dropdown-menu .dropdown-item a icon {
  color: #fff;
}
header#global-header .nav-user .dropdown-menu .dropdown-item a:hover {
  background: #333333;
  color: #4181CF;
  text-decoration: none;
}
header#global-header .nav-user .dropdown-menu .dropdown-item a.active {
  color: #a7c5e9;
}
header#global-header .nav-user .dropdown-menu .dropdown-item.logout a {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  background: #333333;
}
header#global-header .nav-community {
  border-left: 1px solid #595959;
  border-right: 1px solid #595959;
}
header#global-header .nav-community > a.btn {
  margin: 5px 7px;
  padding: 5px 15px;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
}
header#global-header .nav-community > a.btn:after {
  margin-left: 10px;
}
header#global-header .dropdown-menu {
  border-radius: 0 0 4px 4px;
  border: none;
  margin-top: 0px;
  padding: 0;
  -o-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
}
header#global-header .dropdown-menu .dropdown-divider {
  margin: 0;
}
header#global-header .dropdown-menu .dropdown-item {
  padding: 0;
}
header#global-header .dropdown-menu .dropdown-item a {
  display: block;
  padding: 15px 30px;
  font-size: 13px;
  font-weight: 600;
  color: #000;
}
header#global-header .dropdown-menu .dropdown-item a i {
  font-size: 15px;
  font-weight: 500;
  margin-right: 20px;
  float: left;
}
header#global-header .dropdown-menu .dropdown-item a.active, header#global-header .dropdown-menu .dropdown-item a:hover {
  text-decoration: none;
  color: #4181CF;
}
header#global-header .dropdown-menu .dropdown-item:active {
  background: #E3EFF6;
  outline: none;
  box-shadow: none;
}

header#sub-header {
  background: #fff;
  -o-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
}
header#sub-header .navbar {
  padding: 0;
  background: #fff;
}
header#sub-header .navbar .navbar-brand {
  padding: 0 0 0 25px;
  max-height: 80px;
  max-width: 310px;
}
header#sub-header .navbar .navbar-brand img {
  max-height: 80px;
  max-width: 200px;
  display: inline-block;
}
header#sub-header .nav-lang {
  margin-left: 20px;
}
header#sub-header .nav-lang > a.nav-link {
  padding: 0 !important;
  margin: 15px 0;
}
header#sub-header .nav-lang .btn {
  font-size: 12px;
  position: relative;
  padding-right: 40px;
}
header#sub-header .nav-lang .btn i:first-child {
  margin: 3px 10px 3px 0;
  float: left;
  display: inline;
}
header#sub-header .nav-lang .btn i:last-child {
  position: absolute;
  right: 10px;
  margin: 3px 0 3px 10px;
  display: inline;
}
header#sub-header .nav-lang .btn span {
  font-weight: 700;
  color: #4181CF;
  display: inline;
}
header#sub-header .nav-lang .dropdown-menu {
  margin-top: -15px;
}
header#sub-header .nav-lang .dropdown-menu button {
  display: block;
  padding: 15px 30px;
  font-size: 13px;
  font-weight: 600;
  color: #000;
}
header#sub-header .nav-lang .dropdown-menu button:active {
  background: #E3EFF6;
  outline: none;
  box-shadow: none;
}
header#sub-header .dropdown-menu {
  border-radius: 0 0 4px 4px;
  border: none;
  margin-top: 1px;
  padding: 0;
  -o-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
}
header#sub-header .dropdown-menu .dropdown-divider {
  margin: 0;
}
header#sub-header .dropdown-menu .dropdown-item {
  padding: 0;
}
header#sub-header .dropdown-menu .dropdown-item a {
  display: block;
  padding: 10px 30px;
  font-size: 13px;
  font-weight: 600;
  color: #000;
}
header#sub-header .dropdown-menu .dropdown-item a i {
  font-size: 15px;
  font-weight: 500;
  margin-right: 20px;
  float: left;
}
header#sub-header .dropdown-menu .dropdown-item a.active, header#sub-header .dropdown-menu .dropdown-item a:hover {
  text-decoration: none;
  color: #4181CF;
}
header#sub-header .dropdown-menu .dropdown-item.active, header#sub-header .dropdown-menu .dropdown-item:active {
  background: #E3EFF6;
  outline: none;
  box-shadow: none;
}
header#sub-header .rootnav .root-item.nav-item > a {
  color: #000;
  display: block;
  text-align: center;
  padding: 19px 25px 18px 25px;
  height: 100%;
  min-width: 100px;
}
header#sub-header .rootnav .root-item.nav-item > a i {
  font-size: 18px;
  display: inline-block;
  margin-right: 7px;
  float: left;
  line-height: 22px;
  margin-top: 2px;
}
header#sub-header .rootnav .root-item.nav-item > a i.icon {
  line-height: normal;
  line-height: initial;
  margin-top: 2px;
}
header#sub-header .rootnav .root-item.nav-item > a span {
  display: inline-block;
  font-size: 13px;
  line-height: 12px;
  font-weight: 600;
}
header#sub-header .rootnav .root-item.nav-item > a:hover, header#sub-header .rootnav .root-item.nav-item > a.active {
  text-decoration: none;
  color: #4181CF;
  background: none;
}
header#sub-header .rootnav .root-item.nav-item.show > a {
  background: #e6e6e6;
}

#menu-toggle {
  background: none;
  border: none;
  float: left;
  margin: 10px 5px;
  min-width: auto;
  padding: 10px 15px;
  text-align: center;
}
#menu-toggle span {
  font-size: 20px;
  font-weight: 800;
}
#menu-toggle:active, #menu-toggle:focus {
  box-shadow: none;
}

.navbar-toggler img {
  height: 15px;
}

.wrap {
  padding: 40px 0;
  min-height: 100vh;
  height: 100%;
  position: relative;
}

.main-content {
  height: 100%;
  position: relative;
  display: inline-block;
  width: 100%;
  margin-top: -40px;
  padding-top: 30px;
}
.main-content > div > .alert {
  margin-left: -36px;
  margin-right: -36px;
}

.page-title {
  font-size: 32px;
  font-weight: 900;
  margin: 0 0 20px 0;
  color: #000;
}

.sub-title {
  font-size: 22px;
  font-weight: 800;
  margin-bottom: 20px;
  color: #888;
}

.empty-placeholder {
  width: 100%;
  position: absolute;
  right: 30px;
}

.btn-bar {
  float: right;
}
.btn-bar.custom-bar {
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  background: rgba(148, 148, 148, 0.09);
}
.btn-bar .btn.btn-add {
  padding: 10px 20px 10px 60px;
  min-width: auto;
  font-size: 13px;
}
.btn-bar .btn.btn-request-approval {
  padding: 10px 20px 10px 20px;
  min-width: auto;
  font-size: 13px;
}
.btn-bar .wrapper-picker {
  position: relative;
}
.btn-bar .wrapper-picker > .control-label {
  position: absolute;
  z-index: 2;
  left: 15px;
  top: 14px;
}
.btn-bar .wrapper-picker .picker-from {
  padding: 10px 15px 10px 60px !important;
  width: 220px;
}
.btn-bar .wrapper-picker .picker-to {
  padding: 10px 15px 10px 40px !important;
  width: 220px;
}
@media screen and (max-width: 1199px) {
  .btn-bar .ActMore {
    margin-top: 10px;
  }
}
@media screen and (max-width: 768px) {
  .btn-bar .ReqApp {
    margin-top: 10px;
  }
}

.tbl-search {
  float: right;
  width: 200px;
  margin-right: 20px;
  height: auto;
  padding: 10px 15px 10px 45px;
  font-family: "Open Sans", sans-serif;
  border-radius: 4px;
  border: 1px solid #cdcdcd;
  -o-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  margin-bottom: 10px;
  background: #fff url(/static/media/search.c1515dae.svg);
  background-size: 13px;
  background-repeat: no-repeat;
  background-position: 12px 14px;
}
.tbl-search__absolute {
  position: absolute;
  top: 1rem;
  right: 0.5rem;
}

.wr-bank-select.form-group {
  display: flex;
  align-items: center;
}
.wr-bank-select.form-group .control-label {
  margin-right: 10px;
  margin-bottom: 0;
}
.wr-bank-select.form-group .bank-select.form-control {
  min-width: 0;
  min-width: initial;
  width: auto;
  background: #fff !important;
  border: 1px solid #cdcdcd !important;
  line-height: 18px;
  padding: 7px 20px;
  -o-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
}
.wr-bank-select.form-group .bank-select.form-control:after {
  margin-left: 10px;
}
.wr-bank-select.form-group .bank-select.form-control:active, .wr-bank-select.form-group .bank-select.form-control:focus, .wr-bank-select.form-group .bank-select.form-control:active:focus {
  color: #333 !important;
}

.show > .bank-select {
  color: #333 !important;
}

.nested-list {
  padding: 0;
  max-height: 400px;
  overflow-y: auto;
}
.nested-list .nested-list-item-wrapper {
  border-top: 1px solid #dedede;
  padding: 5px 0;
}
.nested-list .nested-list-item-wrapper:first-child {
  border-top: none;
}
.nested-list .nested-list-item {
  font-size: 15px;
  font-weight: 600;
  padding: 5px 15px;
}
.nested-list .nested-list-item:hover {
  background: #4181CF;
  color: #fff;
}
.nested-list .nested-list-item:active, .nested-list .nested-list-item:focus, .nested-list .nested-list-item:active:focus {
  background: #dedede !important;
  color: #333 !important;
}
.nested-list .nested-list-child {
  padding: 5px 20px 5px 30px;
  display: inline-block;
  width: 100%;
}
.nested-list .nested-list-child:hover {
  background: #4181CF;
  color: #fff;
}
.nested-list .nested-list-child:hover h5,
.nested-list .nested-list-child:hover span {
  color: #fff;
}
.nested-list .nested-list-child:active, .nested-list .nested-list-child:focus, .nested-list .nested-list-child:active:focus {
  background: #dedede !important;
  color: #333 !important;
}
.nested-list .nested-list-child:active h5,
.nested-list .nested-list-child:active span, .nested-list .nested-list-child:focus h5,
.nested-list .nested-list-child:focus span, .nested-list .nested-list-child:active:focus h5,
.nested-list .nested-list-child:active:focus span {
  color: #333;
}
.nested-list .nested-list-child h5 {
  font-size: 13px;
  margin: 0 10px 0 0;
  line-height: 15px;
  font-weight: 600;
  display: inline-block;
}
.nested-list .nested-list-child span {
  font-size: 13px;
  font-weight: 600;
  color: #888;
}

/* Tables */
.custom-tbl {
  margin-bottom: 0;
}
.custom-tbl.invoice-tbl td {
  vertical-align: top;
}
.custom-tbl tbody tr {
  background: #fff;
}
.custom-tbl tbody tr:last-child {
  border-radius: 0 0 5px 5px;
}
.custom-tbl tbody tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}
.custom-tbl tbody tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}
.custom-tbl tbody tr:hover {
  background: #E3EFF6;
  cursor: pointer;
}
.custom-tbl tbody tr:hover:last-child {
  border-bottom: 1px solid #dedede;
  border-radius: 0 0 5px 5px;
}
.custom-tbl tbody tr:hover:last-child td:first-child {
  border-bottom-left-radius: 5px;
}
.custom-tbl tbody tr:hover:last-child td:last-child {
  border-bottom-right-radius: 5px;
}
.custom-tbl tbody tr:hover .btn-tbl {
  background: #fff;
  -o-box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
}
.custom-tbl tbody tr.expanding-row td {
  padding: 20px 0 !important;
}
.custom-tbl tbody tr.expanding-row td > div {
  max-height: 300px;
  overflow-y: auto;
}
.custom-tbl tbody tr.expanding-row td > div .custom-tbl td {
  padding: 15px 20px !important;
}
.custom-tbl th {
  border: none;
  background: none;
  color: #333;
  font-size: 12px;
  text-transform: uppercase;
  padding: 15px 20px;
}
.custom-tbl th:first-child {
  border-top-left-radius: 7px;
}
.custom-tbl th:last-child {
  border-top-right-radius: 7px;
}
.custom-tbl th.import-status {
  text-align: center;
  width: 250px;
}
.custom-tbl th.sortable span:before, .custom-tbl th.sortable span:after {
  font-weight: 800;
}
.custom-tbl th.col-general, .custom-tbl th.col-name, .custom-tbl th.col-id, .custom-tbl th.col-actions, .custom-tbl th.col-designation {
  width: 150px;
}
.custom-tbl th.col-company {
  width: 200px;
}
.custom-tbl th.col-email {
  width: 300px;
}
.custom-tbl th.col-amount {
  width: 170px;
  text-align: right;
}
.custom-tbl th.col-code {
  width: 100px;
}
.custom-tbl th.col-status {
  width: 120px;
}
.custom-tbl th.col-date {
  width: 115px;
}
.custom-tbl th.col-method {
  width: 200px;
}
.custom-tbl th.col-invoice-id {
  width: 150px;
}
.custom-tbl td {
  font-size: 14px;
  font-weight: 600;
  padding: 20px 20px;
  vertical-align: middle;
  color: #111;
}
.custom-tbl td .btn {
  margin-left: 15px;
}
.custom-tbl td .btn:first-child {
  margin-left: 0;
}
.custom-tbl td.import-status {
  text-align: center;
  width: 150px;
}
.custom-tbl td.import-status .progress-bar {
  font-weight: 600;
}
.custom-tbl td.import-status .status {
  width: 100px;
  text-align: left;
  margin: auto;
}
.custom-tbl td.import-status .status i {
  font-weight: 800;
  margin-right: 10px;
}
.custom-tbl td.import-status .status span {
  font-weight: 800;
  text-transform: uppercase;
}
.custom-tbl td.import-status.success {
  color: #28a728;
}
.custom-tbl td.import-status.fail {
  color: #dd0000;
}
.custom-tbl td.credit {
  font-weight: 800;
  text-align: right;
  color: #28a728;
}
.custom-tbl td.debit {
  font-weight: 800;
  text-align: right;
  color: #dd0000;
}
.custom-tbl td.col-amount {
  text-align: right;
}
.custom-tbl td.col-email {
  width: 300px;
  word-break: break-word;
}
.custom-tbl td.col-company {
  width: 200px;
}
.custom-tbl td.col-actions {
  width: 150px;
}
.custom-tbl td.col-date {
  width: 115px;
  white-space: nowrap;
}
.custom-tbl.reports-tbl td.success {
  color: #28a728;
}
.custom-tbl.reports-tbl td.warning {
  color: #e6aa02;
}
.custom-tbl.reports-tbl td.fail {
  color: #dd0000;
}
.custom-tbl .selection-input-4 {
  position: relative;
  margin-left: 0 !important;
}
.custom-tbl .selection-input-4:before {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #f7f7f7;
  border: 1px solid #d2cece;
  position: absolute;
  left: -5px;
  top: -3px;
}
.custom-tbl .selection-input-4:checked:before {
  background: #8bc34a;
  border-color: #8bc34a;
}
.custom-tbl .selection-input-4:checked:after {
  font-family: Linearicons-Free;
  content: "\e87f";
  color: #fff;
  position: absolute;
  opacity: 1;
  font-size: 24px;
  top: -10px;
  left: -6px;
}
.custom-tbl .selection-input-4:indeterminate:before {
  background: #adadad;
  border-color: #adadad;
}
.custom-tbl .selection-input-4:indeterminate:after {
  font-family: Linearicons-Free;
  content: "\e882";
  color: #fff;
  position: absolute;
  opacity: 1;
  font-size: 24px;
  top: -10px;
  left: -6px;
}
.custom-tbl.selection-tbl {
  border-spacing: 0 8px;
  border-collapse: separate;
}
.custom-tbl.selection-tbl td {
  padding: 15px 20px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}
.custom-tbl.selection-tbl td:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.custom-tbl.selection-tbl td:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.custom-tbl.selection-tbl .selection-row td {
  border-top: 2px solid #b0d683;
  border-bottom: 2px solid #b0d683;
}
.custom-tbl.selection-tbl .selection-row td:first-child {
  border-left: 2px solid #b0d683;
}
.custom-tbl.selection-tbl .selection-row td:last-child {
  border-right: 2px solid #b0d683;
}
.custom-tbl.selection-tbl tr.xero-import-table-failed td {
  color: #c16262;
  background: #fff7f7;
}
.custom-tbl.selection-tbl tr.xero-import-table-failed .selection-input-4 {
  opacity: 0.5;
}
.custom-tbl.headless-tbl > thead th[data-row-selection] {
  padding: 0;
  height: 0;
}
.custom-tbl.payment-cycles > thead {
  display: none;
}
.custom-tbl.payment-cycles > tbody tr td.expand-cell {
  width: 55px;
}
.custom-tbl.expand-tbl > tbody > tr > td {
  text-align: left;
  border-spacing: 0 15px;
}
.custom-tbl.expand-tbl > tbody > tr > td i {
  font-weight: 800;
}
.custom-tbl.expand-tbl > tbody > tr.expanding-row {
  background: #ebf0f3;
}
.custom-tbl.expand-tbl > tbody > tr.expanding-row:hover {
  background: #ebf0f3;
}
.custom-tbl.expand-tbl > tbody > tr.expanding-row .react-bootstrap-table {
  border: 1px solid #d0d0d0;
}
.custom-tbl.expand-tbl > tbody > tr.expanding-row table.custom-tbl {
  background: none;
}
.custom-tbl.expand-tbl > tbody > tr.expanding-row table.custom-tbl th {
  padding: 10px 20px;
  background: none;
}
.custom-tbl.expand-tbl > tbody > tr.expanding-row table.custom-tbl tr {
  background: none;
}
.custom-tbl.expand-tbl > tbody > tr.expanding-row table.custom-tbl tr:hover {
  border: none;
}
.custom-tbl.expand-tbl > tbody > tr.expanding-row table.custom-tbl tr:hover th {
  background: none;
}
.custom-tbl.expand-tbl > tbody > tr.expanding-row table.custom-tbl td {
  background: #fff;
  padding: 10px 20px;
}
.custom-tbl.expand-tbl > tbody > tr.expanding-row table.custom-tbl tbody > tr:hover {
  border: none;
}
.custom-tbl.expand-tbl > tbody > tr.expanding-row table.custom-tbl tbody > tr:hover > td {
  color: #4181CF;
}
.custom-tbl.ppsr-search-result-tbl tbody tr:hover {
  background: #e3eff6;
  cursor: default;
}
.custom-tbl.ppsr-search-result-tbl tbody .ppsr-invoice-id {
  text-decoration: underline;
  cursor: pointer;
}
.custom-tbl.stacked-tbl th {
  border: 1px solid #dee2e6;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.custom-tbl.stacked-tbl th:first-child {
  border-left: 0;
}
.custom-tbl.stacked-tbl th:last-child {
  border-right: none;
}
@media (max-width: 1400px) {
  .custom-tbl th,
.custom-tbl td {
    width: auto !important;
    word-break: break-word !important;
  }
}

.cell-dropdown {
  display: inline-block;
  padding: 3px 8px;
  background: #efefef;
  border-radius: 5px;
}
.cell-dropdown i {
  margin-left: 15px;
  font-weight: 800;
  font-size: 11px;
}

.selection-footer {
  background: #ffffff;
  font-weight: 600;
  font-size: 14px;
  padding: 15px 15px 0 15px;
  color: #9a9a9a;
  border-top: 1px solid #eaeaea;
  text-align: right;
}

.filter-criteria {
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  margin-right: 15px;
  margin-bottom: 5px;
}
.filter-criteria label {
  font-size: 14px;
  color: #777;
  text-transform: uppercase;
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #999;
  display: inline-block;
}
.filter-criteria span {
  display: inline-block;
  margin-right: 5px;
}

/* PAGINATION */
.react-bootstrap-table-pagination {
  margin: 10px 0 0 0;
}
.react-bootstrap-table-pagination .react-bs-table-sizePerPage-dropdown .dropdown-menu {
  min-width: 0;
  min-width: initial;
}
.react-bootstrap-table-pagination #pageDropDown {
  background: #efefef;
  padding: 5px 15px;
  border: none;
  min-width: 0;
  min-width: initial;
}
.react-bootstrap-table-pagination #pageDropDown:active, .react-bootstrap-table-pagination #pageDropDown:focus {
  color: #000;
}
.react-bootstrap-table-pagination .pagination .page-item .page-link {
  color: #4181CF;
}
.react-bootstrap-table-pagination .pagination .page-item.active .page-link {
  color: #fff;
  background: #4181CF;
  border-color: #4181CF;
}

.company-id img {
  max-height: 40px;
}
.company-id h5 {
  font-size: 15px;
  font-weight: 800;
  padding: 0;
  margin: 0;
  word-break: break-word;
}
.company-id span {
  font-size: 12px;
  color: #999;
}

/* MODAL */
.custom-modal .modal-header {
  border: none;
  padding-bottom: 0;
}
.custom-modal .modal-title {
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
}
.custom-modal .modal-body {
  color: #666;
  font-size: 14px;
  font-weight: 600;
}
.custom-modal .modal-footer {
  border: none;
  padding-top: 0;
}
.custom-modal .modal-footer .btn-xs {
  padding: 5px 25px;
}

.modal .modal-title {
  color: #262626;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.close {
  cursor: pointer;
  z-index: 1;
}

.doc-tile {
  padding: 15px;
  width: 200px;
  height: 100px;
  text-align: center;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  -o-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
}
.doc-tile .doc-icon {
  font-size: 20px;
  margin-bottom: 5px;
  transition: all 0.3s;
}
.doc-tile span {
  display: block;
  font-size: 13px;
  font-weight: 600;
  word-break: break-all;
}
.doc-tile:hover {
  cursor: pointer;
  transform: translate(0px, -2px);
  -o-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.08);
  background: #f9f9f9;
}
.doc-tile:hover .doc-icon:before {
  content: "\e865";
}
.doc-tile .close {
  position: absolute;
  display: inline-block;
  right: 0;
  top: 0;
  opacity: 1;
  padding: 5px;
  border-radius: 0 7px 0 0;
  transition: all 0.3s;
}
.doc-tile .close i {
  font-weight: 800;
}
.doc-tile .close:hover {
  background: #dd0000;
  color: #fff;
}
.doc-tile.doc-large {
  width: 200px;
  height: 150px;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
}
.doc-tile.doc-large h5 {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 20px;
}

.card {
  border-radius: 7px;
  -o-box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.02);
  box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.02);
}
.card .card-body {
  padding: 30px;
}
.card .card-footer .btn {
  min-width: 200px;
  text-align: center;
}
.card.card-add {
  -o-box-shadow: none;
  box-shadow: none;
  background: none;
  text-align: center;
  border: 2px dashed #bbb;
  cursor: pointer;
}
.card.card-add .card-body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.card.card-add:hover {
  transform: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.card.card-add:hover {
  text-decoration: none;
  border: 2px dashed #4181CF;
}
.card.card-add:hover i,
.card.card-add:hover span {
  color: #4181CF;
}

.card-confirm {
  max-width: 900px;
}
.card-confirm label {
  font-size: 14px;
  color: #777;
  font-weight: 800;
  display: block;
  margin-bottom: 0;
}
.card-confirm .item-title {
  font-size: 20px;
  color: #222;
  font-weight: 800;
  margin-bottom: 40px;
}
.card-confirm .item-amount h3 {
  font-weight: 800;
  font-size: 20px;
}
.card-confirm .item-amount h3 span {
  font-size: 16px;
  color: #777;
  font-weight: 600;
}
.card-confirm .item-amount h3.receive {
  color: #28a728;
}
.card-confirm .item-info {
  padding: 10px;
  border-top: 1px solid #dedede;
}
.card-confirm .item-info:first-child {
  border-top: none;
}
.card-confirm .item-info span {
  font-weight: 600;
  font-size: 16px;
}

.card-prompt {
  border: 2px dashed #84ade0;
  background: #c3d8f3;
}
.card-prompt .card-header {
  background: none;
  margin: 0 0px;
  padding: 0 0 10px 0;
  font-weight: 600;
  font-size: 14px;
  color: #333;
  border: none;
}
.card-prompt .prompt-body {
  padding: 10px 0px;
}
.card-prompt .tile-prompt {
  display: flex;
  flex-direction: row;
  padding: 15px 10px;
  background: #ffffff;
  border: none;
}
.card-prompt .tile-prompt > i {
  font-size: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
}
.card-prompt .tile-prompt h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 10px 0;
}
.card-prompt .tile-prompt ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.card-prompt .tile-prompt ul li > i {
  margin-right: 10px;
  font-weight: 600;
}
.card-prompt .tile-prompt ul li > i.lnr-checkmark-circle {
  color: #28a728;
}
.card-prompt .tile-prompt ul li span {
  font-size: 14px;
  font-weight: 600;
  color: #28a728;
}
.card-prompt .tile-prompt ul li a {
  font-size: 14px;
  color: #4181CF;
}

.integration-selector label {
  width: 100%;
  font-size: 1rem;
}
.integration-selector .card-community .card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.integration-selector .card-community h2 {
  font-size: 20px;
  font-weight: 800;
}
.integration-selector .card-community p {
  margin: 0;
  font-size: 13px;
  font-weight: 600;
  color: #888;
}
.integration-selector .card-community.card-disabled {
  opacity: 0.6;
  pointer-events: none;
  background-color: #eee;
}
.integration-selector .card-input-element + .card {
  height: calc(140px + 2rem);
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  transition: all 0.2s;
}
.integration-selector .card-input-element + .card:hover {
  cursor: pointer;
  transform: translate(0px, -2px);
}
.integration-selector .card-input-element:checked + .card {
  border: 2px solid var(--primary);
  transition: border 0.3s;
}
.integration-selector .card-input-element:checked + .card h2 {
  color: #4181CF;
}
.integration-selector .card-input-element:checked + .card::after {
  position: absolute;
  right: 15px;
  top: 10px;
  content: "✓";
  color: #4181CF;
  font-size: 24px;
  -webkit-animation-name: fadeInCheckbox;
  animation-name: fadeInCheckbox;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.tile-dash {
  height: 130px;
  padding: 15px;
}
.tile-dash:hover {
  cursor: pointer;
}
.tile-dash.tile-summary {
  display: flex;
  flex-direction: row;
}
.tile-dash.tile-summary > i {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 30px;
  padding: 0 20px;
  margin-right: 10px;
}
.tile-dash.tile-summary > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.tile-dash h4 {
  font-size: 16px;
  font-weight: 600;
}
.tile-dash p,
.tile-dash p > span {
  margin: 0;
  font-size: 26px;
  font-weight: 800;
}
.tile-dash span {
  font-size: 14px;
  font-weight: 600;
  color: #666;
}
.tile-dash.pending p,
.tile-dash.pending p > span {
  color: #e6aa02;
}
.tile-dash.financed p,
.tile-dash.financed p > span {
  color: #28a728;
}

.custom-tabs-horizontal .nav-tabs {
  background: #fff;
  border-radius: 8px 8px 0 0;
  border: 1px solid rgba(0, 0, 0, 0.09);
}
.custom-tabs-horizontal .nav-tabs .nav-item a {
  display: inline-block;
  background: none;
  border: none;
  padding: 20px 30px;
  font-size: 13px;
  font-weight: 800;
  text-transform: uppercase;
  color: #4181CF;
}
.custom-tabs-horizontal .nav-tabs .nav-item a span {
  display: inline-block;
}
.custom-tabs-horizontal .nav-tabs .nav-item a span i {
  display: inline-block;
  width: 11px;
  height: 11px;
  background: #4181CF;
  border-radius: 50%;
  margin-right: 10px;
}
.custom-tabs-horizontal .nav-tabs .nav-item a.active span {
  position: relative;
}
.custom-tabs-horizontal .nav-tabs .nav-item a.active span:after {
  display: inline-block;
  height: 4px;
  width: 100%;
  content: "";
  background: #4181CF;
  position: absolute;
  bottom: -20px;
  left: 0;
}
.custom-tabs-horizontal .nav-tabs .nav-item:hover {
  cursor: pointer;
}

.tab-card {
  border-radius: 0 0 10px 10px;
}
.tab-card .card-body {
  position: relative;
}
.tab-card.tab-card-details {
  min-height: 0;
  min-height: initial;
}
.tab-card.tab-card-details .table {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}
.tab-card.tab-card-details .table tr:first-child th,
.tab-card.tab-card-details .table tr:first-child td {
  border: none;
}
.tab-card.tab-card-details .table th {
  font-size: 13px;
}
.tab-card.tab-card-details .table td {
  font-weight: 600;
}
.tab-card.tab-card-details .table.table-offer td {
  text-align: right;
}
.tab-card.tab-card-details .table.table-offer td.td-link {
  text-align: left;
}
.tab-card.tab-card-details .table.table-offer td.td-link i {
  color: #4181CF;
  font-size: 16px;
}
.tab-card.tab-card-details .table.table-offer td.td-link .separator {
  color: #4181CF;
}
.tab-card.custom-tab-card {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.finance-request-detail .tab-card {
  min-height: 0;
  min-height: initial;
}
.finance-request-detail .card-invoice .invoice-issued {
  margin-bottom: 15px;
}
.finance-request-detail .card-invoice .invoice-dates > div {
  border-left: 1px solid #efefef;
}
.finance-request-detail .card-invoice .invoice-dates > div:first-child {
  border: none;
}
.finance-request-detail .card-invoice .item-rating h5 {
  font-weight: 600;
  text-transform: uppercase;
}

.financing-wizard .wizard-header {
  display: flex;
  flex-direction: row;
  align-content: center;
}
.financing-wizard .wizard-header > div:first-child {
  background: #efefef;
  margin: -12px 20px -12px -20px;
  padding: 12px 15px;
  border-top-left-radius: 10px;
}
.financing-wizard .wizard-header span {
  height: 100%;
  font-weight: 800;
  margin-top: 12px;
  display: inline-block;
  padding: 0 10px;
  color: #4181CF;
}
.financing-wizard .wizard-header h3 {
  font-size: 18px;
  font-weight: 800;
  margin: 0 0 5px 0;
}
.financing-wizard .wizard-header p {
  margin: 0;
  font-size: 14px;
}
.financing-wizard .wizard-body .card-offer .item-amount {
  margin-top: -10px;
}
.financing-wizard .wizard-body .card-offer .item-status {
  margin-top: -50px;
  margin-bottom: 20px;
}
.financing-wizard .search-placeholder {
  text-align: center;
  padding: 25px 15px;
}

.custom-tabs {
  margin: -40px;
  display: flex;
}

.inner-sidebar {
  width: 250px;
  background: #fff;
  padding: 20px 15px;
  position: absolute;
  left: -15px;
  height: 100%;
  -o-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.08);
  border-left: 2px solid #ebf0f3;
  overflow-y: auto;
}
.inner-sidebar .nav-title {
  padding: 0 10px;
}
.inner-sidebar .nav-title i {
  float: left;
  margin-top: 14px;
  margin-right: 10px;
  font-size: 20px;
}
.inner-sidebar .nav-title span {
  text-align: center;
  font-size: 14px;
  font-weight: 900;
  margin: 0;
  text-transform: uppercase;
}
.inner-sidebar .nav-tabs {
  display: block;
  margin-top: 15px;
}
.inner-sidebar .nav-tabs .nav-item {
  display: block;
  border-bottom: 1px solid #efefef;
  position: relative;
}
.inner-sidebar .nav-tabs .nav-item a {
  display: block;
  font-size: 13px;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  padding: 13px 20px 13px 40px;
  transition: all 0.2s ease-out;
  color: #000;
}
.inner-sidebar .nav-tabs .nav-item a.active, .inner-sidebar .nav-tabs .nav-item a.active:hover {
  border: none;
  font-weight: 900;
  color: #4181CF;
}
.inner-sidebar .nav-tabs .nav-item a.active:after, .inner-sidebar .nav-tabs .nav-item a.active:hover:after {
  content: "\e876";
  font-family: Linearicons-Free;
  speak: none;
  font-style: normal;
  font-weight: 900;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 11px;
  margin-left: 10px;
}
.inner-sidebar .nav-tabs .nav-item a:hover {
  background: #E3EFF6;
  border: none;
  cursor: pointer;
  text-decoration: none;
}
.inner-sidebar .nav-tabs .nav-item a.status:before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  background: #ccc;
  position: absolute;
  left: 15px;
  top: 18px;
}
.inner-sidebar .nav-tabs .nav-item a.status.new:before {
  background: #4181CF;
}
.inner-sidebar .nav-tabs .nav-item a.status.pending:before {
  background: #e6aa02;
}
.inner-sidebar .nav-tabs .nav-item a.status.error:before {
  background: #dd0000;
}
.inner-sidebar .nav-tabs .nav-item a.status.complete:before {
  background: #28a728;
}
.inner-sidebar .nav-tabs .nav-item a.status.archive:before {
  background: #ccc;
}
.inner-sidebar .nav-tabs .nav-item a.status.financed:before {
  background: #28a728;
}

.inner-content {
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-top: 20px;
  padding: 30px 30px;
  position: relative;
}

.inner-title {
  font-size: 32px;
  font-weight: 600;
  margin: 0 0 30px 0;
  color: #000;
  letter-spacing: -1px;
}
.inner-title.list-title {
  margin-bottom: 20px;
}

.financing-offer-detail .inner-title-financing-details {
  font-size: 32px;
  font-weight: 600;
  color: #000;
  letter-spacing: -1px;
  font-family: "Open Sans", sans-serif;
}
.financing-offer-detail body {
  min-height: calc(100% - 96px);
  overflow-x: hidden;
  font-family: "Open Sans", sans-serif;
  position: relative;
  background: #ebf0f3;
}
.financing-offer-detail a:not([href]):not([tabindex]) {
  color: #000;
  color: initial;
}
.financing-offer-detail .financing-wizard {
  margin-top: 30px;
}
.financing-offer-detail .title-termandc {
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
}
.financing-offer-detail .para-termandc {
  font-family: "Open Sans", sans-serif;
}

a:link {
  text-decoration: none !important;
}

.nav-title.early-payment {
  margin-bottom: 0.5rem !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  font-weight: 900 !important;
  margin-top: 0;
}
.nav-title.early-payment i {
  float: left;
  margin-right: 10px;
  font-size: 20px;
  margin-top: -2px;
}

.breadcrumb {
  font-size: 14px;
  margin-top: -20px;
  margin-left: -28px;
  margin-right: -28px;
  background: none;
  padding: 15px 30px;
}
.breadcrumb > a {
  color: #4181CF !important;
  font-weight: 600;
  display: inline-block;
  margin-right: 15px;
}
.breadcrumb > a:after {
  margin-left: 15px;
  content: "\e876";
  font-family: Linearicons-Free;
  speak: none;
  font-style: normal;
  font-weight: 500;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 10px;
}
.breadcrumb > a:hover {
  text-decoration: none;
}
.breadcrumb > a:hover span {
  text-decoration: underline !important;
  cursor: pointer;
}
.breadcrumb > span {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.wr-item-list {
  clear: both;
}
.wr-item-list .item-list {
  display: flex;
}
.wr-item-list .item-list .item-descriptor {
  width: calc(100% - 200px);
}
.wr-item-list .item-list .actions {
  width: 200px;
  text-align: right;
}
.wr-item-list .item-list .actions button {
  margin-left: 10px;
}

.wr-card-items .card {
  height: 220px;
  margin-bottom: 20px;
  transition: all 0.3s;
  -o-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
}
.wr-card-items .card .card-body {
  padding: 20px;
}
.wr-card-items .card .card-body .wr-card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.wr-card-items .card .item-descriptor {
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;
  color: #333;
}
.wr-card-items .card .item-subtitle {
  font-size: 14px;
  font-weight: 600;
  color: #999;
}
.wr-card-items .card .item-subtitle label {
  color: #333;
}
.wr-card-items .card .item-line {
  margin-top: 3px;
  font-size: 14px;
  font-weight: 600;
  color: #666;
}
.wr-card-items .card .item-line label {
  font-size: 14px;
  color: #333;
  margin: 0;
}
.wr-card-items .card .actions {
  margin: 0 15px;
  background: none;
  padding: 15px 0;
  text-align: right;
}
.wr-card-items .card .actions .btn {
  padding: 5px 30px;
  min-width: auto;
}
.wr-card-items .card:hover {
  transform: translate(0px, -5px);
  -o-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
}
.wr-card-items .card.card-add {
  cursor: pointer;
}
.wr-card-items .card.card-add .card-body {
  margin: 25px;
}
.wr-card-items .card.card-add .placeholder-img {
  height: 100px;
}
.wr-card-items .card.card-add i {
  color: #bbb;
  display: block;
  font-size: 40px;
  margin-right: 15px;
  font-weight: 300;
  transition: all 0.2s;
}
.wr-card-items .card.card-add span {
  font-size: 30px;
  color: #aaa;
  font-weight: 400;
  letter-spacing: -1px;
  transition: all 0.2s;
}
.wr-card-items .card.card-add:hover {
  text-decoration: none;
  border: 2px dashed #4181CF;
}
.wr-card-items .card.card-add:hover i,
.wr-card-items .card.card-add:hover span {
  color: #4181CF;
}
.wr-card-items .card.card-offer, .wr-card-items .card.card-portfolio {
  height: auto;
  height: initial;
}
.wr-card-items.wr-reports .card-report {
  height: auto;
}
.wr-card-items.wr-reports .card-report .card-header h4 {
  font-size: 20px;
  font-weight: 800;
  color: #262626;
  margin-bottom: 0;
}
.wr-card-items.wr-reports .card-report .card-header h4 .badge {
  margin-left: 15px;
  font-size: 13px;
  font-weight: 600;
}
.wr-card-items.wr-reports .card-report .card-header .link {
  margin-left: 20px;
}
.wr-card-items.wr-reports .card-report .card-header .link + .link {
  border-right: 1px solid #dedede;
  padding-right: 20px;
}
.wr-card-items.wr-reports .card-report .card-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  color: #000;
}
.wr-card-items.wr-reports .card-report .report-type {
  margin-left: 5px;
  font-size: 15px;
  font-weight: 600;
}
.wr-card-items.wr-reports .card-report .item-descriptor {
  font-size: 26px;
  line-height: 32px;
  margin-bottom: 15px;
}
.wr-card-items.wr-reports .card-report .item-descriptor.sub-items {
  display: flex;
}
.wr-card-items.wr-reports .card-report label {
  font-weight: 800;
  margin-bottom: 0;
  opacity: 0.8;
}
.wr-card-items.wr-reports .card-report .item-img {
  width: 100px;
  max-height: 100%;
  max-width: 100px;
  margin: 0 35px 0 20px;
  opacity: 0.5;
}
.wr-card-items.wr-reports .card-report .item-source {
  font-size: 16px;
}
.wr-card-items.wr-reports .card-report .item-reference {
  font-size: 16px;
  margin-bottom: 10px;
  word-break: break-word;
}
.wr-card-items.wr-reports .card-report .item-date {
  color: #646464;
  font-size: 13px;
  min-width: 300px;
}
.wr-card-items.wr-reports .card-report .item-date label {
  display: block;
}
.wr-card-items.wr-reports .card-report .item-date > .col:nth-child(2) {
  border-left: 1px solid #dedede;
}
.wr-card-items.wr-reports .card-report .item-view {
  margin-top: 15px;
}
.wr-card-items.wr-reports .card-report .item-view .link + .link {
  border-left: 1px solid #dedede;
  padding-left: 20px;
}
.wr-card-items.wr-reports .card-report .user {
  font-weight: 800;
  margin-bottom: 0;
  opacity: 0.8;
  font-size: 16px;
}
.wr-card-items.wr-reports .card-report .item-icon {
  margin-right: 15px;
}
.wr-card-items.wr-reports .card-report .item-icon i {
  font-size: 26px;
  font-weight: 800;
}
.wr-card-items.wr-reports .card-report .item-grade {
  margin-right: 20px;
}
.wr-card-items.wr-reports .card-report .item-score {
  font-size: 26px;
  display: inline-block;
}
.wr-card-items.wr-reports .card-report .item-grade label,
.wr-card-items.wr-reports .card-report .item-score label {
  color: #222;
}
.wr-card-items.wr-reports .card-report .sub-item-grade {
  font-size: 16px;
}
.wr-card-items.wr-reports .card-report .sub-item-grade span {
  margin-left: 10px;
}
.wr-card-items.wr-reports .card-report .sub-item-grade span.pending {
  color: #ffaa00;
}
.wr-card-items.wr-reports .card-report .sub-item-grade span.failed {
  color: #d10000;
}
.wr-card-items.wr-reports .card-report .sub-item-grade span.completed {
  color: #046848;
}
.wr-card-items.wr-reports .card-report .sub-item-grade .divider {
  border-right: 1px solid #efefef;
  height: 16px;
  margin-right: 10px;
}
.wr-card-items.wr-reports .card-report.success .item-icon i:before {
  content: "\e87f";
}
.wr-card-items.wr-reports .card-report.success .item-icon i,
.wr-card-items.wr-reports .card-report.success .item-descriptor {
  color: #28a728;
}
.wr-card-items.wr-reports .card-report.fail .item-icon i:before {
  content: "\e87c";
}
.wr-card-items.wr-reports .card-report.fail .item-icon i,
.wr-card-items.wr-reports .card-report.fail .item-descriptor {
  color: #dd0000;
}
.wr-card-items.wr-reports .card-report.warning .item-icon .lnr:before {
  content: "\e87c";
}
.wr-card-items.wr-reports .card-report.warning .item-icon i,
.wr-card-items.wr-reports .card-report.warning .item-descriptor {
  color: #e6aa02;
}
.wr-card-items.wr-reports .card-report.default .item-icon i:before {
  content: "\e87d";
}
.wr-card-items.wr-reports .card-report.default .item-icon i,
.wr-card-items.wr-reports .card-report.default .item-descriptor {
  color: #A4A4A4;
}
.wr-card-items.wr-reports .card-report .list-group {
  border-radius: 0;
  border: none;
  border-left: 1px solid #dedede;
  height: 100%;
}
.wr-card-items.wr-reports .card-report .list-group .list-group-item {
  font-size: 14px;
  border: none;
  border-radius: 0;
  padding: 10px 15px;
  border-top: 1px solid #efefef;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wr-card-items.wr-reports .card-report .list-group .list-group-item:first-child {
  border: none;
}
.wr-card-items.wr-reports .card-report .list-group .list-group-item a {
  text-decoration: underline;
  font-size: 13px;
  display: block;
  width: 100%;
}
.wr-card-items.wr-reports .card-report .list-group .list-group-item i {
  font-size: 15px;
  font-weight: 800;
  float: right;
}
.wr-card-items.wr-reports .card-report .list-group .list-group-item.success i {
  color: #28a728;
}
.wr-card-items.wr-reports .card-report .list-group .list-group-item.success i:before {
  content: "\e87f";
}
.wr-card-items.wr-reports .card-report .list-group .list-group-item.fail i {
  color: #dd0000;
}
.wr-card-items.wr-reports .card-report .list-group .list-group-item.fail i:before {
  content: "\e87c";
}
.wr-card-items.wr-reports .card-report .list-group .list-group-item.default i, .wr-card-items.wr-reports .card-report .list-group .list-group-item.pending i {
  color: #A4A4A4;
}
.wr-card-items.wr-reports .card-report .list-group .list-group-item.default i:before, .wr-card-items.wr-reports .card-report .list-group .list-group-item.pending i:before {
  content: "\e87d";
}
.wr-card-items.wr-reports .card-report .list-group .list-group-item:hover {
  background: #efefef;
}
.wr-card-items.wr-reports .card-report .empty-text {
  min-height: 100px;
  font-size: 22px;
  color: #A4A4A4;
  font-weight: 600;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.wr-card-items.wr-reports .card-report.credit-rating-excellent .item-icon i,
.wr-card-items.wr-reports .card-report.credit-rating-excellent .item-descriptor {
  color: #0f9246;
}
.wr-card-items.wr-reports .card-report.credit-rating-very-good .item-icon i,
.wr-card-items.wr-reports .card-report.credit-rating-very-good .item-descriptor {
  color: #7dbb42;
}
.wr-card-items.wr-reports .card-report.credit-rating-good .item-icon i,
.wr-card-items.wr-reports .card-report.credit-rating-good .item-descriptor {
  color: #fecc09;
}
.wr-card-items.wr-reports .card-report.credit-rating-fair .item-icon i,
.wr-card-items.wr-reports .card-report.credit-rating-fair .item-descriptor {
  color: #f68e1f;
}
.wr-card-items.wr-reports .card-report.credit-rating-poor .item-icon i,
.wr-card-items.wr-reports .card-report.credit-rating-poor .item-descriptor {
  color: #ef4723;
}
.wr-card-items.wr-reports .card-report.credit-rating-very-bad .item-icon i,
.wr-card-items.wr-reports .card-report.credit-rating-very-bad .item-descriptor {
  color: #bc2026;
}
.wr-card-items.wr-reports .card-report .item-descriptor.failed span,
.wr-card-items.wr-reports .card-report .item-descriptor.failed i {
  color: #dd0000;
}
.wr-card-items.wr-reports .card-report .item-descriptor.initiated span,
.wr-card-items.wr-reports .card-report .item-descriptor.initiated i {
  color: #4181CF;
}
.wr-card-items.wr-reports .card-report .item-descriptor.completed span,
.wr-card-items.wr-reports .card-report .item-descriptor.completed i {
  color: #28a728;
}
.wr-card-items.wr-reports .card-report .item-descriptor.pending span,
.wr-card-items.wr-reports .card-report .item-descriptor.pending i {
  color: #e6aa02;
}
.wr-card-items.wr-reports-detail .card-report {
  height: auto;
}
.wr-card-items.wr-reports-detail .card-report:hover {
  transform: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.wr-card-items.wr-reports-detail .card-report .card-body {
  display: block;
}
.wr-card-items.wr-reports-detail .card-report .item-img {
  width: 40px;
  max-height: 100%;
  max-width: 60px;
  margin: 0 20px 0 20px;
  opacity: 0.5;
}
.wr-card-items.wr-reports-detail .card-report .card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.wr-card-items.wr-reports-detail .card-report .card-header .badge {
  position: absolute;
  right: 25px;
  font-size: 18px;
}
.wr-card-items.wr-reports-detail .card-report .report-summary {
  padding: 15px;
  background: #efefef;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.wr-card-items.wr-reports-detail .card-report .item-descriptor {
  margin: 0;
  text-align: center;
}
.wr-card-items.wr-reports-detail .card-report .item-descriptor > span {
  font-size: 30px;
}
.wr-card-items.wr-reports-detail .card-report .item-descriptor > span label {
  font-size: 16px;
  display: block;
}
.wr-card-items.wr-reports-detail .card-report .report-status i {
  margin-right: 10px;
}
.wr-card-items.wr-reports-detail .card-report .report-status.Completed {
  color: #28a728;
}
.wr-card-items.wr-reports-detail .card-report .report-status.success {
  color: #28a728;
}
.wr-card-items.wr-reports-detail .card-report .report-status.success i {
  color: #28a728;
}
.wr-card-items.wr-reports-detail .card-report .report-status.success i:before {
  content: "\e87f";
}
.wr-card-items.wr-reports-detail .card-report .report-status.Initiated {
  color: #4181CF;
}
.wr-card-items.wr-reports-detail .card-report .report-status.Initiated i {
  color: #4181CF;
}
.wr-card-items.wr-reports-detail .card-report .report-status.fail {
  color: #dd0000;
}
.wr-card-items.wr-reports-detail .card-report .report-status.fail i {
  color: #dd0000;
}
.wr-card-items.wr-reports-detail .card-report .report-status.fail i:before {
  content: "\e87c";
}
.wr-card-items.wr-reports-detail .card-report .report-status.Pending {
  color: #e6aa02;
}
.wr-card-items.wr-reports-detail .card-report .report-status.default, .wr-card-items.wr-reports-detail .card-report .report-status.pending {
  color: #646464;
}
.wr-card-items.wr-reports-detail .card-report .report-status.default i, .wr-card-items.wr-reports-detail .card-report .report-status.pending i {
  color: #646464;
}
.wr-card-items.wr-reports-detail .card-report .report-status.default i:before, .wr-card-items.wr-reports-detail .card-report .report-status.pending i:before {
  content: "\e87d";
}
.wr-card-items.wr-reports-detail .card-report .item-score .poor {
  color: #ff8c00;
}
.wr-card-items.wr-reports-detail .card-report .item-score .very-poor {
  color: #dd0000;
}
.wr-card-items.wr-reports-detail .card-report .item-score .fair {
  color: #e6aa02;
}
.wr-card-items.wr-reports-detail .card-report .item-score .good {
  color: #8bc34a;
}
.wr-card-items.wr-reports-detail .card-report .item-score .excellent {
  color: #28a728;
}
.wr-card-items.wr-reports-detail .card-report .item-score .marginal {
  color: #ffbc42;
}
.wr-card-items.wr-finance-requests {
  margin: 0 -30px 20px -30px;
  background: #dfe5e8;
  padding: 20px 30px 0px 30px;
}
.wr-card-items.wr-finance-requests .card-request {
  height: 200px;
  width: 300px;
  float: left;
  margin-right: 20px;
}
.wr-card-items.wr-finance-requests .card-request.mini {
  height: 100px;
}
.wr-card-items.wr-finance-requests .card-request .card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.wr-card-items.wr-finance-requests .card-request .card-footer.invoice-balance span {
  font-weight: 400;
  display: inline-block;
  margin-right: 15px;
  color: #555;
  font-size: 16px;
}
.wr-card-items.wr-finance-requests .card-request .card-footer.invoice-balance h3 {
  font-weight: 800;
  text-align: right;
  font-size: 18px;
  margin-bottom: 0;
}
.wr-card-items.wr-finance-requests .card-request .item-descriptor {
  font-size: 14px;
  color: #A4A4A4;
}
.wr-card-items.wr-finance-requests .card-request .item-subtitle {
  color: #333;
  font-size: 17px;
  margin-bottom: 10px;
}
.wr-card-items.wr-finance-requests .card-request .item-line {
  font-size: 13px;
}
.wr-card-items.wr-finance-requests .card-request .item-line label {
  font-size: 14px;
  color: #333;
  margin-left: 10px;
  font-weight: 800;
}
.wr-card-items.wr-finance-requests .card-request.selected {
  background: #A0C6F1;
}
.wr-card-items.wr-finance-requests .card-request.selected:hover {
  transform: none;
}
.wr-card-items.wr-finance-requests .card-request.selected .item-descriptor {
  color: #4181CF;
}
.wr-card-items.wr-finance-requests .card-request.disabled {
  opacity: 0.7;
}
.wr-card-items.wr-finance-requests .card-request.disabled:hover {
  transform: none;
}

.card-finance {
  height: auto;
  height: initial;
}
.card-finance .item-descriptor {
  font-size: 26px;
  margin-bottom: 10px;
  font-weight: 800;
  color: #333;
}
.card-finance .inner-finance {
  display: flex;
  flex-direction: row;
}
.card-finance .inner-finance label {
  margin-bottom: 0;
  font-size: 15px;
  display: block;
  color: #777;
}
.card-finance .inner-finance span {
  font-size: 20px;
  color: #222;
  display: block;
}
.card-finance .item-amount {
  float: right;
  text-align: right;
}
.card-finance .item-amount label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #777;
  margin: 0;
}
.card-finance .item-amount h3 {
  font-size: 40px;
  font-weight: 800;
  margin: 0;
}
.card-finance .item-amount h3 span {
  font-weight: 400;
  display: inline-block;
  margin-right: 10px;
  color: #777;
  font-size: 30px;
}
.card-finance .item-amount h3.receive {
  color: #28a728;
}

.card-offer {
  height: auto;
  height: initial;
  border: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.card-offer .card-body {
  padding: 0;
}
.card-offer .offer-type {
  margin-top: -5px;
  margin-left: 20px;
}
.card-offer .item-status {
  float: right;
  margin-top: 10px;
  /* margin-bottom: 40px; */
  text-transform: uppercase;
  padding: 0;
  background: none;
  font-size: 15px;
}
.card-offer .item-status.badge-success {
  color: #28a728;
}
.card-offer .item-status.badge-warning {
  color: #e6aa02;
}
.card-offer .status-help {
  float: left;
  margin-top: 10px;
  color: #777;
}
.card-offer .item-descriptor {
  font-size: 26px;
  margin-bottom: 10px;
  font-weight: 800;
  color: #333;
}
.card-offer .inner-finance {
  flex: 0.9 0;
  display: flex;
  flex-direction: row;
}
.card-offer .inner-finance label {
  margin-bottom: 0;
  font-size: 15px;
  display: block;
  color: #777;
}
.card-offer .inner-finance span {
  font-size: 18px;
  color: #222;
  display: block;
  font-weight: 600;
}
.card-offer .table {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}
.card-offer .table tr:first-child th,
.card-offer .table tr:first-child td {
  border: none;
}
.card-offer .table th {
  font-size: 13px;
}
.card-offer .table td {
  font-weight: 600;
}
.card-offer .table.table-offer td {
  text-align: right;
}
.card-offer .item-amount {
  clear: right;
  float: right;
  text-align: right;
  margin-left: 20px;
  margin-top: 20px;
}
.card-offer .item-amount label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #777;
  margin: 0;
}
.card-offer .item-amount h3 {
  font-size: 40px;
  font-weight: 800;
  margin: 0;
}
.card-offer .item-amount h3 span {
  font-weight: 400;
  display: inline-block;
  color: #777;
  font-size: 30px;
}
.card-offer .item-amount h3 span:nth-child(2) {
  font-size: 40px;
  font-weight: 800;
  color: #333;
}
.card-offer .item-amount h3.receive {
  color: #28a728;
}
.card-offer .item-amount h3.receive span:nth-child(2) {
  font-size: 40px;
  font-weight: 800;
  color: #28a728;
}

.card-portfolio {
  height: auto;
  height: initial;
}
.card-portfolio .card-body {
  position: relative;
}
.card-portfolio .card-body .inner-portfolio {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.card-portfolio .card-body .item-descriptor {
  font-size: 26px;
  margin-bottom: 10px;
  font-weight: 800;
  color: #333;
  margin-right: 200px;
}
.card-portfolio .card-body .item-line {
  min-width: 250px;
  font-size: 22px;
  color: #777;
}
.card-portfolio .card-body .item-line label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #777;
  margin: 0;
}
.card-portfolio .card-body .item-line span {
  font-size: 22px;
  color: #222;
  font-weight: 600;
}
.card-portfolio .card-body .item-line.pay span {
  color: #dd0000;
}
.card-portfolio .card-body .item-line.receive span {
  color: #28a728;
}
.card-portfolio .card-body .item-amount {
  float: right;
  text-align: right;
  margin-top: -20px;
}
.card-portfolio .card-body .item-amount label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #777;
  margin: 0;
}
.card-portfolio .card-body .item-amount h3 {
  font-size: 40px;
  font-weight: 800;
  margin: 0;
}
.card-portfolio .card-body .item-amount h3 span {
  font-weight: 400;
  display: inline-block;
  margin-right: 10px;
  color: #777;
  font-size: 30px;
}
.card-portfolio .card-body .item-amount h3 span:nth-child(2) {
  font-size: 40px;
  font-weight: 800;
  color: #333;
}
.card-portfolio .card-body .item-amount h3.receive {
  color: #28a728;
}
.card-portfolio .card-action {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 20px;
  display: flex;
  align-items: center;
}

.detail-data label {
  font-weight: 800;
  margin-right: 10px;
  font-size: 14px;
}

.report-lines .list-group-item {
  margin-bottom: 30px;
  border-radius: 5px;
  padding: 30px;
}
.report-lines .list-group-item[disabled], .report-lines .list-group-item.disabled {
  color: #999;
}
.report-lines .list-group-item .list-group-item-heading {
  font-size: 24px;
  margin: 0;
  font-weight: 800;
}
.report-lines .list-group-item .list-group-item-heading span {
  float: right;
}
.report-lines .list-group-item .list-group-item-subheading {
  font-size: 16px;
  margin: 20px 0;
  font-weight: 800;
}
.report-lines .list-group-item .progress {
  margin-top: 10px;
  height: 5px;
}
.report-lines .list-group-item .progress .progress-bar {
  background: #e6aa02;
  font-weight: 800;
  color: #222;
}
.report-lines .list-group-item .list-group-item-text {
  margin: 0;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 600;
  border-top: 1px solid #dedede;
}
.report-lines .list-group-item .list-group-item-text:hover {
  background: #efefef;
}
.report-lines .list-group-item .list-group-item-text:first-of-type {
  margin-top: 20px;
  border-top: none;
}
.report-lines .list-group-item .list-group-item-text[disabled] {
  color: #999;
}
.report-lines .list-group-item .list-group-item-text span {
  font-weight: 800;
  float: right;
}
.report-lines.credit-check .table-responsive {
  max-height: 400px;
}
.report-lines.credit-check .table-responsive .custom-tbl th {
  background-color: #efefef;
  border: 1px solid #dee2e6;
}
.report-lines.credit-check .table-responsive .custom-tbl th:first-child {
  border-left: 0;
}
.report-lines.credit-check .table-responsive .custom-tbl th:last-child {
  border-right: none;
}
.report-lines.credit-check .table-responsive .custom-tbl th.col-wide {
  min-width: 500px;
}
.report-lines.credit-check .table-responsive .custom-tbl td .allotment-list tbody tr {
  background: transparent;
}
.report-lines.credit-check .table-responsive .custom-tbl td .allotment-list tbody td {
  padding: 0;
}

.report-log .list-group-item {
  font-size: 13px;
}

.wr-list-users .list-group-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.wr-list-users .list-user {
  font-size: 14px;
  font-weight: 600;
  color: #222;
  display: inline-block;
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid #dedede;
}
.wr-list-users .list-role {
  font-size: 12px;
  color: #646464;
  font-weight: 600;
}
.wr-list-users .list-user-role {
  font-size: 14px;
  font-weight: 600;
  color: #222;
  display: inline-block;
  padding-right: 20px;
  margin-right: 20px;
}
.wr-list-users .close {
  position: absolute;
  right: 15px;
  top: calc(50% - 13px);
}
.wr-list-users .close i {
  font-size: 18px;
}

.split-bg {
  background: #4181CF;
  position: absolute;
  min-height: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.split-bg .first {
  width: calc(50% - 250px);
  min-height: 100%;
  background: #154881;
  position: absolute;
  bottom: 0;
}
.split-bg .first:after {
  background: #154881;
  position: absolute;
  content: " ";
  display: block;
  top: -15%;
  bottom: -15%;
  right: -200px;
  transform: rotate(20deg);
  width: 500px;
  height: 120%;
}

.card-onboarding {
  border: none;
  border-radius: 20px;
  overflow: hidden;
  -o-box-shadow: 0 10px 50px rgba(0, 0, 0, 0.16);
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.16);
  transform: translateY(-40px);
  transition: opacity 0.3s ease-in;
  transition: transform 0.5s ease-in;
}
.card-onboarding .onboarding-left,
.card-onboarding .onboarding-right {
  padding: 20px;
  text-align: center;
}
.card-onboarding .onboarding-left {
  background: #003169;
  color: #fff;
  position: relative;
}
.card-onboarding .onboarding-left h1 {
  font-size: 30px;
  font-weight: 500;
}
.card-onboarding .onboarding-left p {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 300;
  margin: 0;
}
.card-onboarding .onboarding-right {
  background: #fff;
}
.card-onboarding .onboarding-right h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.wr-status {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}
.wr-status .badge {
  width: 50%;
}
.wr-status .badge span {
  text-transform: none;
  font-size: 12px;
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}

.invoice-status {
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 0 7px 0 0;
}
.invoice-status.badge-warning {
  background: #e6aa02;
  color: #fff;
}
.invoice-status.badge-default {
  background: #dedede;
  color: #000;
}

.finance-status {
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 7px 0 0 0;
}
.finance-status.badge-warning {
  background: #e6aa02;
  color: #fff;
}

.invoice-action__disabled {
  pointer-events: none;
  opacity: 0.8;
}

.card-invoice label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #777;
  margin: 0;
}
.card-invoice .invoice-issued {
  margin-bottom: 20px;
}
.card-invoice .invoice-issued h4 {
  font-size: 22px;
  font-weight: 800;
  margin: 0;
  color: #000;
}
.card-invoice .invoice-items span {
  font-weight: 800;
  font-size: 16px;
  display: block;
  min-width: 180px;
  color: #000;
}
.card-invoice .invoice-dates span {
  font-weight: 800;
  font-size: 16px;
}
.card-invoice .invoice-tax {
  text-align: right;
  background: #efefef;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  float: right;
}
.card-invoice .invoice-tax h3 {
  color: #777;
  font-size: 20px;
  margin: 0;
}
.card-invoice .invoice-tax h3 span {
  display: inline-block;
  margin-right: 10px;
}
.card-invoice .cash-values .invoice-issued {
  margin-bottom: 10px;
}

.invoice-instrument h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  color: #bbb;
  text-align: right;
  margin-bottom: 20px;
}

.invoice-balance {
  text-align: right;
  clear: both;
}
.invoice-balance h3 {
  font-size: 40px;
  font-weight: 800;
  margin: 0;
}
.invoice-balance h3.pay {
  color: #dd0000;
}
.invoice-balance h3.pay span:nth-child(2) {
  color: #dd0000;
}
.invoice-balance h3 span:first-child {
  margin-right: 10px;
}
.invoice-balance h3 span:nth-child(2) {
  font-size: 40px;
  font-weight: 800;
}
.invoice-balance h3.receive {
  color: #28a728;
}
.invoice-balance h3.receive span:nth-child(2) {
  font-size: 40px;
  font-weight: 800;
  color: #28a728;
}
.invoice-balance h3 span {
  font-weight: 400;
  display: inline-block;
  color: #777;
  font-size: 30px;
}

.line-card {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}
.line-card .cashflow-tbl thead.cashflow-header th {
  border-bottom: none;
}
.line-card .cashflow-tbl tbody > tr td {
  border-top: none;
}

.line-total .invoice-balance label {
  margin-top: 15px;
}
.line-total .invoice-balance h3 span:nth-child(2) {
  font-size: 30px;
}
.line-total table td {
  vertical-align: middle;
  text-align: right;
}
.line-total table td label {
  margin-right: 20px;
}
.line-total table tr td {
  text-align: right;
}

.wr-captcha {
  min-height: 78px;
}

.form-legend {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 0;
}

.form-helptext {
  display: block;
  font-weight: 600;
  color: #555;
  margin-bottom: 20px;
}

.form-group {
  text-align: left;
  margin-bottom: 25px;
  font-family: "Open Sans", sans-serif;
  /* UPLOADER */
}
.form-group .control-label {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #333;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.form-group .control-label i.verified {
  color: #28a728;
  font-weight: 800;
}
.form-group.required .control-label:after {
  color: red;
  content: " *";
}
.form-group.wr-integration .card-subheading {
  font-size: 12px;
  color: #A4A4A4;
  padding-bottom: 10px;
}
.form-group.wr-integration .card-subheading.text-alert {
  color: red;
}
.form-group.wr-integration .disabled label {
  color: #A4A4A4;
}
.form-group.wr-integration .iconinfo {
  margin-right: 5px;
  font-size: medium;
}
.form-group .data-label {
  font-size: 14px;
  font-weight: 500;
}
.form-group .form-control {
  height: auto;
  padding: 13px 15px;
  font-family: "Open Sans", sans-serif;
  border-radius: 4px;
  border: 1px solid #cdcdcd;
  -o-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
}
.form-group .form-control[disabled] {
  background: #dedede;
}
.form-group .form-control::-moz-placeholder {
  text-overflow: ellipsis;
}
.form-group .form-control:-moz-placeholder {
  text-overflow: ellipsis;
}
.form-group .form-control::-webkit-input-placeholder {
  text-overflow: ellipsis;
}
.form-group .form-control:-ms-input-placeholder {
  padding-left: 0px;
  text-indent: 20px;
  text-overflow: ellipsis;
}
.form-group .form-control:placeholder-shown {
  padding-left: 0px;
  text-indent: 20px;
  text-overflow: ellipsis;
}
.form-group textarea {
  min-height: 100px;
  line-height: 18px;
}
.form-group .custom-control {
  padding-left: 30px;
}
.form-group .custom-control.custom-radio .custom-control-label::before {
  border: 2px solid #a4a4a4;
  width: 22px;
  height: 22px;
  left: -30px;
  top: 1px;
}
.form-group .custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border: 1px solid #4181CF;
}
.form-group .custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
  width: 18px;
  height: 18px;
  background: #4181CF;
  border-radius: 50%;
  margin-top: 2px;
  margin-left: 2px;
  border: 1px solid #fff;
  left: -30px;
  top: 1px;
}
.form-group .react-datepicker-wrapper {
  cursor: pointer;
  display: block;
  position: relative;
}
.form-group .react-datepicker-wrapper .react-datepicker__input-container {
  display: block;
  z-index: 9;
}
.form-group .react-datepicker-wrapper .react-datepicker__input-container .form-control {
  background: none;
  padding-right: 50px;
  cursor: pointer;
}
.form-group .react-datepicker-wrapper .react-datepicker__input-container .form-control[disabled] {
  background: #dedede;
}
.form-group .react-datepicker-wrapper:before {
  position: absolute;
  top: 7px;
  right: 10px;
  display: inline-block;
  content: "\e836";
  font-family: Linearicons-Free;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  z-index: 1;
}
.form-group .react-datepicker-wrapper:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: inline-block;
  content: "";
  background: #fff;
}
.form-group .react-datepicker-wrapper .react-datepicker__close-icon {
  position: absolute;
  right: 40px;
}
.form-group .react-datepicker-popper {
  z-index: 99;
}
.form-group .react-datepicker-popper .react-datepicker {
  border: none;
  -o-box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.15);
  background: #fff;
}
.form-group .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  border-bottom-color: #4181CF;
}
.form-group .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-bottom: none;
}
.form-group .react-datepicker-popper .react-datepicker__header,
.form-group .react-datepicker-popper .react-datepicker__day--selected {
  background: #4181CF;
}
.form-group .react-datepicker-popper .react-datepicker__navigation--next {
  border-left-color: #fff;
}
.form-group .react-datepicker-popper .react-datepicker__navigation--previous {
  border-right-color: #fff;
}
.form-group .react-datepicker-popper .react-datepicker__current-month,
.form-group .react-datepicker-popper .react-datepicker__day-name {
  color: #fff;
}
.form-group .custom-checkbox {
  padding-left: 45px;
}
.form-group .custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  background: #4181CF;
}
.form-group .custom-checkbox .custom-control-label {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.5px;
  margin-top: 7px;
}
.form-group .custom-checkbox .custom-control-label:before, .form-group .custom-checkbox .custom-control-label:after {
  width: 30px;
  height: 30px;
  left: -45px;
  top: 0;
}
.form-group .custom-checkbox ~ .text-danger {
  margin-left: 45px;
}
.form-group .text-danger {
  font-size: 12px;
  font-weight: 600;
  margin: 5px 0 0 0px;
}
.form-group .uppy-Dashboard--wide .uppy-Dashboard-inner {
  width: auto;
  height: auto;
}
.form-group .uppy-Dashboard--wide .uppy-Dashboard-inner .uppy-Dashboard-innerWrap {
  min-height: 150px;
}
.form-group .uppy-Dashboard--wide .uppy-Dashboard-inner .uppy-Dashboard-bgIcon .UppyIcon {
  height: 50px;
}
.form-group .uppy-Dashboard--wide .uppy-Dashboard-inner .uppy-Dashboard-dropFilesTitle {
  margin-top: 15px;
}

.new-dashboard .tile-dash {
  height: 90px;
}
.new-dashboard .inner-title {
  font-size: 30px;
  font-weight: bold;
}
.new-dashboard .content-title {
  color: #000;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
}
.new-dashboard .sub-title {
  color: #646464;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.new-dashboard .dashboard-filters .form-group {
  display: flex;
  align-items: center;
}
.new-dashboard .dashboard-filters .form-group .control-label {
  color: #262626;
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
}
.new-dashboard .dashboard-filters .form-group .form-control.custom-select {
  color: #262626;
  font-size: 14px;
  font-weight: bold;
}
.new-dashboard hr {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .new-dashboard .overall-stats.col-xl-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.new-dashboard .tile-summary p {
  color: #262626;
  font-weight: bold;
  font-size: 26px;
}
.new-dashboard .tile-summary p span {
  color: #262626;
  font-weight: bold;
  font-size: 26px;
}
.new-dashboard .tile-summary span {
  color: #000;
  font-weight: 600;
  font-size: 15px;
}
.new-dashboard .tile-summary i {
  color: #646464;
}
.new-dashboard .tile-summary .btn-primary {
  background-color: #244b99;
  border-radius: 4px;
}
.new-dashboard .tile-summary .btn-primary i {
  color: #fff;
}
.new-dashboard .tile-summary .btn-primary i::before {
  font-weight: 600;
}
.new-dashboard .payables,
.new-dashboard .receivables,
.new-dashboard .tile-stat {
  height: 100%;
  width: 100%;
}
.new-dashboard .payables .row,
.new-dashboard .receivables .row,
.new-dashboard .tile-stat .row {
  height: 100%;
  width: 100%;
}
.new-dashboard .payables .row > div,
.new-dashboard .receivables .row > div,
.new-dashboard .tile-stat .row > div {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-around;
}
.new-dashboard .payables h5,
.new-dashboard .receivables h5,
.new-dashboard .tile-stat h5 {
  color: #646464;
  font-weight: normal;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 0;
}
@media (max-width: 1200px) {
  .new-dashboard .payables h5,
.new-dashboard .receivables h5,
.new-dashboard .tile-stat h5 {
    font-size: 15px;
  }
}
.new-dashboard .payables .lbl-data,
.new-dashboard .receivables .lbl-data,
.new-dashboard .tile-stat .lbl-data {
  color: #000;
  font-weight: 600;
  font-size: 15px;
  white-space: nowrap;
}
@media (max-width: 1200px) {
  .new-dashboard .payables .lbl-data,
.new-dashboard .receivables .lbl-data,
.new-dashboard .tile-stat .lbl-data {
    font-size: 12px;
  }
}
.new-dashboard .payables .lbl-stat,
.new-dashboard .receivables .lbl-stat,
.new-dashboard .tile-stat .lbl-stat {
  font-weight: bold;
  font-size: 20px;
  white-space: nowrap;
}
.new-dashboard .payables .lbl-stat.pre-approved, .new-dashboard .payables .lbl-stat.draft,
.new-dashboard .receivables .lbl-stat.pre-approved,
.new-dashboard .receivables .lbl-stat.draft,
.new-dashboard .tile-stat .lbl-stat.pre-approved,
.new-dashboard .tile-stat .lbl-stat.draft {
  color: #717f82;
}
.new-dashboard .payables .lbl-stat.pending,
.new-dashboard .receivables .lbl-stat.pending,
.new-dashboard .tile-stat .lbl-stat.pending {
  color: #ffaa00;
}
.new-dashboard .payables .lbl-stat.approved,
.new-dashboard .receivables .lbl-stat.approved,
.new-dashboard .tile-stat .lbl-stat.approved {
  color: #244b99;
}
.new-dashboard .payables .lbl-stat.accepted,
.new-dashboard .receivables .lbl-stat.accepted,
.new-dashboard .tile-stat .lbl-stat.accepted {
  color: #428bca;
}
.new-dashboard .payables .lbl-stat.declined, .new-dashboard .payables .lbl-stat.payable-financed,
.new-dashboard .receivables .lbl-stat.declined,
.new-dashboard .receivables .lbl-stat.payable-financed,
.new-dashboard .tile-stat .lbl-stat.declined,
.new-dashboard .tile-stat .lbl-stat.payable-financed {
  color: #d10000;
}
.new-dashboard .payables .lbl-stat.paid,
.new-dashboard .receivables .lbl-stat.paid,
.new-dashboard .tile-stat .lbl-stat.paid {
  color: #57c7e1;
}
.new-dashboard .payables .lbl-stat.completed, .new-dashboard .payables .lbl-stat.receivable-financed,
.new-dashboard .receivables .lbl-stat.completed,
.new-dashboard .receivables .lbl-stat.receivable-financed,
.new-dashboard .tile-stat .lbl-stat.completed,
.new-dashboard .tile-stat .lbl-stat.receivable-financed {
  color: #046848;
}
@media (max-width: 1200px) {
  .new-dashboard .payables .lbl-stat,
.new-dashboard .receivables .lbl-stat,
.new-dashboard .tile-stat .lbl-stat {
    font-size: 15px;
  }
}
@media (min-width: 1201px) {
  .new-dashboard .payables .lbl-stat,
.new-dashboard .receivables .lbl-stat,
.new-dashboard .tile-stat .lbl-stat {
    width: 200px;
  }
}
.new-dashboard .payables .total-payable,
.new-dashboard .payables .total-receivable,
.new-dashboard .receivables .total-payable,
.new-dashboard .receivables .total-receivable,
.new-dashboard .tile-stat .total-payable,
.new-dashboard .tile-stat .total-receivable {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
@media (min-width: 1400px) {
  .new-dashboard .payables .total-payable,
.new-dashboard .payables .total-receivable,
.new-dashboard .receivables .total-payable,
.new-dashboard .receivables .total-receivable,
.new-dashboard .tile-stat .total-payable,
.new-dashboard .tile-stat .total-receivable {
    align-items: flex-end;
  }
}
.new-dashboard .payables .total-payable .lbl-receivable span,
.new-dashboard .payables .total-receivable .lbl-receivable span,
.new-dashboard .receivables .total-payable .lbl-receivable span,
.new-dashboard .receivables .total-receivable .lbl-receivable span,
.new-dashboard .tile-stat .total-payable .lbl-receivable span,
.new-dashboard .tile-stat .total-receivable .lbl-receivable span {
  color: #1cc28c;
  font-weight: bold;
  font-size: 30px;
  white-space: nowrap;
}
.new-dashboard .payables .total-payable .lbl-payable,
.new-dashboard .payables .total-receivable .lbl-payable,
.new-dashboard .receivables .total-payable .lbl-payable,
.new-dashboard .receivables .total-receivable .lbl-payable,
.new-dashboard .tile-stat .total-payable .lbl-payable,
.new-dashboard .tile-stat .total-receivable .lbl-payable {
  color: rgba(209, 0, 0, 0.6);
  font-weight: bold;
  font-size: 30px;
  white-space: nowrap;
}
.new-dashboard .payables .payablesFinancingSummary-0,
.new-dashboard .payables .payablesFinancingSummary-1,
.new-dashboard .payables .receivablesFinancingSummary-0,
.new-dashboard .payables .receivablesFinancingSummary-1,
.new-dashboard .receivables .payablesFinancingSummary-0,
.new-dashboard .receivables .payablesFinancingSummary-1,
.new-dashboard .receivables .receivablesFinancingSummary-0,
.new-dashboard .receivables .receivablesFinancingSummary-1,
.new-dashboard .tile-stat .payablesFinancingSummary-0,
.new-dashboard .tile-stat .payablesFinancingSummary-1,
.new-dashboard .tile-stat .receivablesFinancingSummary-0,
.new-dashboard .tile-stat .receivablesFinancingSummary-1 {
  margin-left: -10px;
  width: 100%;
}
@media screen and (max-width: 1400px) {
  .new-dashboard .payables .payablesFinancingSummary-0 svg,
.new-dashboard .payables .payablesFinancingSummary-1 svg,
.new-dashboard .payables .receivablesFinancingSummary-0 svg,
.new-dashboard .payables .receivablesFinancingSummary-1 svg,
.new-dashboard .receivables .payablesFinancingSummary-0 svg,
.new-dashboard .receivables .payablesFinancingSummary-1 svg,
.new-dashboard .receivables .receivablesFinancingSummary-0 svg,
.new-dashboard .receivables .receivablesFinancingSummary-1 svg,
.new-dashboard .tile-stat .payablesFinancingSummary-0 svg,
.new-dashboard .tile-stat .payablesFinancingSummary-1 svg,
.new-dashboard .tile-stat .receivablesFinancingSummary-0 svg,
.new-dashboard .tile-stat .receivablesFinancingSummary-1 svg {
    width: 375px !important;
  }
}
@media screen and (max-width: 768px) {
  .new-dashboard .payables .payablesFinancingSummary-0 svg,
.new-dashboard .payables .payablesFinancingSummary-1 svg,
.new-dashboard .payables .receivablesFinancingSummary-0 svg,
.new-dashboard .payables .receivablesFinancingSummary-1 svg,
.new-dashboard .receivables .payablesFinancingSummary-0 svg,
.new-dashboard .receivables .payablesFinancingSummary-1 svg,
.new-dashboard .receivables .receivablesFinancingSummary-0 svg,
.new-dashboard .receivables .receivablesFinancingSummary-1 svg,
.new-dashboard .tile-stat .payablesFinancingSummary-0 svg,
.new-dashboard .tile-stat .payablesFinancingSummary-1 svg,
.new-dashboard .tile-stat .receivablesFinancingSummary-0 svg,
.new-dashboard .tile-stat .receivablesFinancingSummary-1 svg {
    width: 100% !important;
  }
}
.new-dashboard .payables .invoice-summary,
.new-dashboard .receivables .invoice-summary,
.new-dashboard .tile-stat .invoice-summary {
  display: flex;
  align-items: center;
  width: 100%;
}
.new-dashboard .payables .invoice-summary > div,
.new-dashboard .receivables .invoice-summary > div,
.new-dashboard .tile-stat .invoice-summary > div {
  margin-right: 20px;
  margin-top: 10px;
  width: 100%;
}
.new-dashboard .payables .invoice-summary > div:first-child,
.new-dashboard .receivables .invoice-summary > div:first-child,
.new-dashboard .tile-stat .invoice-summary > div:first-child {
  justify-content: space-between !important;
}
.new-dashboard .payables .invoice-summary > div:last-child,
.new-dashboard .receivables .invoice-summary > div:last-child,
.new-dashboard .tile-stat .invoice-summary > div:last-child {
  justify-content: space-around;
}
.new-dashboard .payables .invoice-summary > div > div,
.new-dashboard .receivables .invoice-summary > div > div,
.new-dashboard .tile-stat .invoice-summary > div > div {
  margin-bottom: 10px;
}
.new-dashboard .payables .invoice-summary > div > div:last-child,
.new-dashboard .receivables .invoice-summary > div > div:last-child,
.new-dashboard .tile-stat .invoice-summary > div > div:last-child {
  margin-bottom: 0;
  margin-right: 0;
}
@media screen and (min-width: 1400px) {
  .new-dashboard .payables .invoice-summary > div,
.new-dashboard .receivables .invoice-summary > div,
.new-dashboard .tile-stat .invoice-summary > div {
    display: flex;
    align-items: center;
  }
  .new-dashboard .payables .invoice-summary > div > div,
.new-dashboard .receivables .invoice-summary > div > div,
.new-dashboard .tile-stat .invoice-summary > div > div {
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
    margin-bottom: 0;
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .new-dashboard .payables,
.new-dashboard .receivables,
.new-dashboard .tile-stat {
    margin-bottom: 10px;
    min-height: 250px;
  }
}
@media screen and (min-width: 1400px) {
  .new-dashboard .payables .summary,
.new-dashboard .receivables .summary,
.new-dashboard .tile-stat .summary {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  .new-dashboard .payables .summary h5,
.new-dashboard .receivables .summary h5,
.new-dashboard .tile-stat .summary h5 {
    margin-top: 10px;
  }
}
.new-dashboard .DateRangePicker {
  white-space: nowrap;
  min-width: 400px;
  height: 48px;
}
.new-dashboard .DateRangePicker > div {
  height: 100%;
}
.new-dashboard .DateRangePicker .DateInput_input {
  color: #262626;
  font-size: 14px;
  font-weight: bold;
  height: 100%;
}
.new-dashboard .DateRangePicker .DateInput__small {
  height: 100%;
}
.new-dashboard .DateRangePicker .DateRangePickerInput__withBorder {
  border-radius: 4px;
  -o-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
  height: 48px;
}
.new-dashboard .DateRangePicker .DateRangePickerInput_clearDates {
  position: absolute;
  right: 130px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.new-dashboard .DateRangePicker .DateRangePickerInput_calendarIcon {
  margin: 0;
  height: 100%;
}
.new-dashboard .DateRangePicker .DateRangePickerInput_calendarIcon:focus {
  outline: none;
  background-color: #eee;
}
.new-dashboard .dashboard-piechart-tooltip {
  background: #eee;
  -o-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
  color: #333;
  display: none;
  font-size: 12px;
  left: 160px;
  padding: 10px 20px;
  position: absolute;
  text-align: center;
  top: 80px;
  min-width: 300px;
  z-index: 10;
}
.new-dashboard .dashboard-piechart-tooltip .amount {
  font-weight: bold;
  font-size: 18px;
}
.new-dashboard .dashboard-piechart-tooltip .amount span.pre-approved, .new-dashboard .dashboard-piechart-tooltip .amount span.draft {
  color: #717f82;
}
.new-dashboard .dashboard-piechart-tooltip .amount span.pending {
  color: #ffaa00;
}
.new-dashboard .dashboard-piechart-tooltip .amount span.approved {
  color: #244b99;
}
.new-dashboard .dashboard-piechart-tooltip .amount span.accepted {
  color: #428bca;
}
.new-dashboard .dashboard-piechart-tooltip .amount span.declined, .new-dashboard .dashboard-piechart-tooltip .amount span.payable-financed {
  color: #d10000;
}
.new-dashboard .dashboard-piechart-tooltip .amount span.paid {
  color: #57c7e1;
}
.new-dashboard .dashboard-piechart-tooltip .amount span.completed, .new-dashboard .dashboard-piechart-tooltip .amount span.receivable-financed {
  color: #046848;
}
.new-dashboard .dashboard-piechart-tooltip .label {
  color: #000;
  font-weight: 600;
  font-size: 14px;
  margin: auto;
  white-space: nowrap;
}
.new-dashboard .dashboard-piechart-tooltip .count {
  white-space: nowrap;
}
.new-dashboard .dashboard-piechart-tooltip .count span {
  font-weight: bold;
  font-size: 14px;
}
.new-dashboard .dashboard-piechart-tooltip .count span.pre-approved, .new-dashboard .dashboard-piechart-tooltip .count span.draft {
  color: #717f82;
}
.new-dashboard .dashboard-piechart-tooltip .count span.pending {
  color: #ffaa00;
}
.new-dashboard .dashboard-piechart-tooltip .count span.approved {
  color: #244b99;
}
.new-dashboard .dashboard-piechart-tooltip .count span.accepted {
  color: #428bca;
}
.new-dashboard .dashboard-piechart-tooltip .count span.declined {
  color: #d75555;
}
.new-dashboard .dashboard-piechart-tooltip .count span.paid {
  color: #57c7e1;
}
.new-dashboard .dashboard-piechart-tooltip .count span.completed {
  color: #58ae61;
}
.new-dashboard .dashboard-piechart-tooltip .count .lbl-data {
  font-weight: 400;
  font-size: 13px;
  margin-left: 5px;
}
.new-dashboard .dashboard-piechart-container {
  position: relative;
}
.new-dashboard .financiar-db .tile-stat .invoice-summary > div {
  flex-direction: row;
  display: flex;
  justify-content: space-between !important;
}
.new-dashboard .financiar-db .tile-stat .invoice-summary > div > div {
  width: 250px;
  margin-right: 20px;
}
.new-dashboard .financiar-db .tile-stat .invoice-summary > div > div:last-child {
  margin-right: 0;
}
@media screen and (min-width: 1400px) {
  .new-dashboard .financiar-db .tile-stat {
    max-width: 1200px;
  }
}

.fixed-form-footer {
  position: fixed;
  bottom: 0;
  height: 60px;
  background: rgba(208, 208, 208, 0.8);
  display: flex;
  align-items: center;
  right: 0;
  left: 0;
  justify-content: flex-end;
  padding: 25px;
  z-index: 99;
}

.bank-select {
  min-width: 300px;
}

.wr-company-logo {
  max-width: 400px;
}
.wr-company-logo img {
  max-width: 100%;
}

.rbt .rbt-token .rbt-token-remove-button {
  bottom: 0;
  color: inherit;
  font-size: inherit;
  font-weight: normal;
  opacity: 1;
  outline: none;
  padding: 3px 7px;
  position: absolute;
  right: 0;
  text-shadow: none;
  top: 0px;
}
.rbt .rbt-close {
  position: absolute;
  top: 8px;
  right: 15px;
  font-size: 30px;
  font-weight: 400;
}

.modal .modal-body .uppy-Dashboard-inner {
  width: 100%;
  height: 350px;
}
.modal .modal-body .custom-alert {
  margin-left: -16px;
  margin-right: -16px;
}

.custom-alert {
  margin-top: -73px;
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 30px;
  padding: 5px 25px;
  border-radius: 0;
  font-size: 14px;
  font-weight: 600;
  min-height: 33px;
  color: #fff;
  text-align: center;
  transition: all 0.3s ease-out;
}
.custom-alert.show-alert {
  margin-top: -30px;
}
.custom-alert.alert-success {
  background: #28a728;
}
.custom-alert.alert-danger {
  background: #dd0000;
}
.custom-alert.reports-custom-margin-top {
  margin-top: -20px;
}

.card-onboarding .custom-alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  border: none;
}
.card-onboarding .custom-alert.show-alert {
  margin: 0;
}

.card-confirmation {
  max-width: 600px;
  margin: auto;
  padding: 60px 40px;
  border-radius: 20px;
  -o-box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.15);
}
.card-confirmation .card-icon {
  position: absolute;
  left: calc(50% - 50px);
  top: -40px;
  background: #fff;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  padding: 8px 12px;
  display: inline-block;
}
.card-confirmation .card-icon i {
  font-size: 80px;
}
.card-confirmation .card-title {
  font-size: 40px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.card-confirmation h3 {
  font-size: 20px;
  color: #333;
  font-weight: 700;
  margin-bottom: 20px;
}
.card-confirmation p {
  font-size: 14px;
  font-weight: 600;
}
.card-confirmation.success i,
.card-confirmation.success .card-title {
  color: #28a728;
}

.card-result .card-header h4 {
  margin: 0;
  font-size: 18px;
  float: left;
}
.card-result .card-header .badge {
  float: right;
  font-weight: 600;
  padding: 8px;
  background: #646464;
}
.card-result .import-img {
  width: 60px;
  margin-right: 30px;
}
.card-result .wr-result-info {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.card-result h5 {
  margin: 0 0 10px 0;
  font-size: 24px;
  color: #666;
  font-weight: 300;
}
.card-result .progress {
  height: 5px;
  border-radius: 3px;
  width: 100%;
  float: left;
}
.card-result .progress .progress-bar {
  padding: 0 8px;
  font-size: 14px;
  font-weight: 600;
  background: #28a728;
}
.card-result .progress .progress-bar.bg-success {
  background: #63bd77 !important;
}
.card-result .progress .progress-bar.bg-error {
  background: #d0505d !important;
}
.card-result .overall-status {
  width: 150px;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0.15px;
}
.card-result.complete .overall-status {
  color: #28a728;
}
.card-result .wr-count {
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.card-result .count {
  text-align: center;
}
.card-result .count h6 {
  font-size: 40px;
  font-weight: 800;
  margin: 0 0 0px 0;
}
.card-result .count span {
  font-size: 14px;
  display: block;
}
.card-result .count.success h6 {
  color: #28a728;
}
.card-result .count.fail h6 {
  color: #dd0000;
}

.link {
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  color: #4181CF;
}
.link:hover {
  cursor: pointer;
  color: #225088;
}
.link span {
  text-decoration: underline;
}
.link i {
  margin-left: 10px;
  margin-top: 4px;
}
.link.link-pre i {
  margin-left: 0px;
  margin-top: 4px;
  margin-right: 10px;
}
.link.link-retry {
  color: #dd0000;
}
.link:hover {
  text-decoration: none;
}
.link:hover span {
  text-decoration: underline;
}

.btn-link {
  border: none;
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  color: #4181CF;
  background: none;
}
.btn-link span {
  text-decoration: underline;
}
.btn-link i {
  float: right;
  margin-left: 10px;
  margin-top: 4px;
}
.btn-link:hover {
  text-decoration: none;
  cursor: pointer;
}
.btn-link:hover span {
  text-decoration: underline;
}
.btn-link.btn-cancel {
  color: #666;
  background: none;
}
.btn-link.btn-cancel i {
  float: left;
}

.btn-upload {
  background: #f2f2f2;
  color: #333;
  font-size: 14px;
  margin-bottom: 0;
  border: 2px solid #e9ecef;
}
.btn-upload:hover, .btn-upload:active, .btn-upload:focus, .btn-upload:active:focus, .btn-upload:not(:disabled):not(.disabled):active {
  background: #d9d9d9;
  border-color: #bfbfbf;
}

a.btn,
button.btn {
  border-radius: 6px;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  padding: 10px 30px;
  min-width: 200px;
}
a.btn i,
button.btn i {
  margin-right: 10px;
}
a.btn.btn-pos i,
button.btn.btn-pos i {
  margin: 0 0 0 10px;
}
a.btn.btn-xs,
button.btn.btn-xs {
  padding: 5px 8px;
  font-size: 12px;
  min-width: auto;
}
a.btn.btn-primary,
button.btn.btn-primary {
  background: #4181CF;
  border-color: #4181CF;
  color: #fff;
}
a.btn.btn-primary:hover, a.btn.btn-primary:active, a.btn.btn-primary:focus, a.btn.btn-primary:active:focus, a.btn.btn-primary:not(:disabled):not(.disabled):active,
button.btn.btn-primary:hover,
button.btn.btn-primary:active,
button.btn.btn-primary:focus,
button.btn.btn-primary:active:focus,
button.btn.btn-primary:not(:disabled):not(.disabled):active {
  background: #2d68b0;
  border-color: #2d68b0;
}
a.btn.btn-secondary,
button.btn.btn-secondary {
  background: #A0C6F1;
  border-color: #A0C6F1;
  color: #333;
}
a.btn.btn-secondary:hover, a.btn.btn-secondary:active, a.btn.btn-secondary:focus, a.btn.btn-secondary:active:focus, a.btn.btn-secondary:not(:disabled):not(.disabled):active,
button.btn.btn-secondary:hover,
button.btn.btn-secondary:active,
button.btn.btn-secondary:focus,
button.btn.btn-secondary:active:focus,
button.btn.btn-secondary:not(:disabled):not(.disabled):active {
  background: #74abea;
  border-color: #74abea;
}
a.btn.btn-secondary.btn-add:before, a.btn.btn-secondary.btn-add:after,
button.btn.btn-secondary.btn-add:before,
button.btn.btn-secondary.btn-add:after {
  background: #8ab9ee;
}
a.btn.custom-btn-secondary,
button.btn.custom-btn-secondary {
  position: relative;
  overflow: hidden;
  padding-left: 50px !important;
  padding-right: 30px !important;
}
a.btn.custom-btn-secondary i,
button.btn.custom-btn-secondary i {
  font-size: 12px;
  position: absolute;
  left: 15px;
  z-index: 9;
  top: calc(50% - 6px);
  margin: 0;
}
a.btn.custom-btn-secondary:before,
button.btn.custom-btn-secondary:before {
  display: inline-block;
  content: "";
  background: #3274c5;
  position: absolute;
  width: 20px;
  left: 25px;
  top: -10px;
  bottom: -10px;
  transform: rotate(20deg);
}
a.btn.custom-btn-secondary:after,
button.btn.custom-btn-secondary:after {
  display: inline-block;
  content: "";
  background: #3274c5;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 35px;
}
a.btn.btn-cancel,
button.btn.btn-cancel {
  background: #646464;
  border-color: #646464;
  color: #fff;
}
a.btn.btn-cancel:hover, a.btn.btn-cancel:active, a.btn.btn-cancel:focus, a.btn.btn-cancel:active:focus, a.btn.btn-cancel:not(:disabled):not(.disabled):active,
button.btn.btn-cancel:hover,
button.btn.btn-cancel:active,
button.btn.btn-cancel:focus,
button.btn.btn-cancel:active:focus,
button.btn.btn-cancel:not(:disabled):not(.disabled):active {
  background: #4b4b4b;
  border-color: #4b4b4b;
  color: #fff;
}
a.btn.btn-cancel.btn-add:before, a.btn.btn-cancel.btn-add:after, a.btn.btn-cancel.btn-back:before, a.btn.btn-cancel.btn-back:after,
button.btn.btn-cancel.btn-add:before,
button.btn.btn-cancel.btn-add:after,
button.btn.btn-cancel.btn-back:before,
button.btn.btn-cancel.btn-back:after {
  background: #575757;
}
a.btn.btn-clear,
button.btn.btn-clear {
  background: #d8d8d8;
  color: #333;
  border-color: #bfbfbf;
}
a.btn.btn-clear:hover, a.btn.btn-clear:active, a.btn.btn-clear:focus, a.btn.btn-clear:active:focus, a.btn.btn-clear:not(:disabled):not(.disabled):active,
button.btn.btn-clear:hover,
button.btn.btn-clear:active,
button.btn.btn-clear:focus,
button.btn.btn-clear:active:focus,
button.btn.btn-clear:not(:disabled):not(.disabled):active {
  background: #bfbfbf;
  border-color: #bfbfbf;
}
a.btn.btn-delete,
button.btn.btn-delete {
  background: #dd0000;
  border-color: #dd0000;
  color: #fff;
}
a.btn.btn-delete:hover, a.btn.btn-delete:active, a.btn.btn-delete:focus, a.btn.btn-delete:active:focus, a.btn.btn-delete:not(:disabled):not(.disabled):active,
button.btn.btn-delete:hover,
button.btn.btn-delete:active,
button.btn.btn-delete:focus,
button.btn.btn-delete:active:focus,
button.btn.btn-delete:not(:disabled):not(.disabled):active {
  background: #aa0000;
  border-color: #aa0000;
}
a.btn.btn-delete.btn-add:before, a.btn.btn-delete.btn-add:after, a.btn.btn-delete.btn-back:before, a.btn.btn-delete.btn-back:after,
button.btn.btn-delete.btn-add:before,
button.btn.btn-delete.btn-add:after,
button.btn.btn-delete.btn-back:before,
button.btn.btn-delete.btn-back:after {
  background: #c40000;
}
a.btn.btn-outline-primary,
button.btn.btn-outline-primary {
  text-transform: uppercase;
  border-color: #A0C6F1;
  color: #262626;
}
a.btn.btn-outline-primary:hover, a.btn.btn-outline-primary:active, a.btn.btn-outline-primary:focus, a.btn.btn-outline-primary:active:focus, a.btn.btn-outline-primary:not(:disabled):not(.disabled):active,
button.btn.btn-outline-primary:hover,
button.btn.btn-outline-primary:active,
button.btn.btn-outline-primary:focus,
button.btn.btn-outline-primary:active:focus,
button.btn.btn-outline-primary:not(:disabled):not(.disabled):active {
  background: #A0C6F1;
  color: #262626;
  -o-box-shadow: none;
  box-shadow: none;
}
a.btn.btn-outline,
button.btn.btn-outline {
  text-transform: uppercase;
  border-color: #dd0000;
  color: #dd0000;
}
a.btn.btn-outline img,
button.btn.btn-outline img {
  width: 25px;
}
a.btn.btn-outline .wr-btn,
button.btn.btn-outline .wr-btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
a.btn.btn-outline:hover, a.btn.btn-outline:active, a.btn.btn-outline:focus, a.btn.btn-outline:active:focus, a.btn.btn-outline:not(:disabled):not(.disabled):active,
button.btn.btn-outline:hover,
button.btn.btn-outline:active,
button.btn.btn-outline:focus,
button.btn.btn-outline:active:focus,
button.btn.btn-outline:not(:disabled):not(.disabled):active {
  background: #fdefef;
  color: #aa0000;
  -o-box-shadow: none;
  box-shadow: none;
  border-color: #aa0000;
}
a.btn.btn-outline-remove,
button.btn.btn-outline-remove {
  text-transform: uppercase;
  border-color: #dd0000;
  color: #dd0000;
}
a.btn.btn-outline-remove:hover, a.btn.btn-outline-remove:active, a.btn.btn-outline-remove:focus, a.btn.btn-outline-remove:active:focus, a.btn.btn-outline-remove:not(:disabled):not(.disabled):active,
button.btn.btn-outline-remove:hover,
button.btn.btn-outline-remove:active,
button.btn.btn-outline-remove:focus,
button.btn.btn-outline-remove:active:focus,
button.btn.btn-outline-remove:not(:disabled):not(.disabled):active {
  background: #A0C6F1;
  color: #262626;
  -o-box-shadow: none;
  box-shadow: none;
}
a.btn.btn-next,
button.btn.btn-next {
  position: relative;
  overflow: hidden;
  padding: 10px 80px;
}
a.btn.btn-next i,
button.btn.btn-next i {
  font-size: 12px;
  position: absolute;
  right: 15px;
  z-index: 9;
  top: calc(50% - 6px);
  margin: 0;
}
a.btn.btn-next:before,
button.btn.btn-next:before {
  display: inline-block;
  content: "";
  background: #3274c5;
  position: absolute;
  width: 20px;
  right: 25px;
  top: -10px;
  bottom: -10px;
  transform: rotate(20deg);
}
a.btn.btn-next:after,
button.btn.btn-next:after {
  display: inline-block;
  content: "";
  background: #3274c5;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 35px;
}
a.btn.btn-add, a.btn.btn-back,
button.btn.btn-add,
button.btn.btn-back {
  position: relative;
  overflow: hidden;
  padding-left: 80px;
  padding-right: 80px;
}
a.btn.btn-add i, a.btn.btn-back i,
button.btn.btn-add i,
button.btn.btn-back i {
  font-size: 12px;
  position: absolute;
  left: 15px;
  z-index: 9;
  top: calc(50% - 6px);
  margin: 0;
}
a.btn.btn-add:before, a.btn.btn-back:before,
button.btn.btn-add:before,
button.btn.btn-back:before {
  display: inline-block;
  content: "";
  background: #3274c5;
  position: absolute;
  width: 20px;
  left: 25px;
  top: -10px;
  bottom: -10px;
  transform: rotate(20deg);
}
a.btn.btn-add:after, a.btn.btn-back:after,
button.btn.btn-add:after,
button.btn.btn-back:after {
  display: inline-block;
  content: "";
  background: #3274c5;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 35px;
}
a.btn.btn-tbl,
button.btn.btn-tbl {
  padding: 5px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  min-width: auto;
  background: #efefef;
  color: #999;
  text-align: center;
  border: none;
  transition: all 0.2s;
}
a.btn.btn-tbl i,
button.btn.btn-tbl i {
  float: none;
  position: relative;
  margin: 0;
  font-weight: 800;
}
a.btn.btn-tbl.btn-primary,
button.btn.btn-tbl.btn-primary {
  border: none;
  background: #efefef;
  color: #999;
}
a.btn.btn-tbl.btn-primary:hover,
button.btn.btn-tbl.btn-primary:hover {
  background: #4181CF;
  color: #fff;
}
a.btn.btn-tbl.btn-delete:hover,
button.btn.btn-tbl.btn-delete:hover {
  background: #dd0000;
  color: #fff;
}
a.btn.link,
button.btn.link {
  background: none;
  border: none;
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  color: #4181CF;
  padding: 0;
  min-width: 0;
  min-width: initial;
}

.pagination-dots {
  list-style: none;
  margin: 40px 0 0 0;
  padding: 0;
}
.pagination-dots span {
  margin: 0 5px;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  -o-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.4);
}
.pagination-dots span.current {
  background: #4181CF;
}
.pagination-dots span.complete {
  background: #A0C6F1;
}
.pagination-dots h4 {
  margin: 10px 0 0 0;
  font-size: 13px;
  color: #979797;
  font-weight: 400;
}

.scroll-box {
  background: #efefef;
  max-height: 300px;
  overflow-y: auto;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 20px;
  padding: 10px;
}

#wrapper {
  transition: all 0.5s ease;
  min-height: calc(100vh - 150px);
}
#wrapper.toggled {
  padding-left: 200px;
}
#wrapper.toggled #sidebar-wrapper {
  width: 200px;
}
#wrapper.toggled #page-content-wrapper {
  position: absolute;
  left: 200px;
}

#sidebar-wrapper {
  z-index: 98;
  position: fixed;
  left: 0;
  width: 50px;
  height: 100%;
  margin-left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: #4181CF;
  transition: all 0.5s ease;
  border-right: 1px solid #f3f3f3;
}

#page-content-wrapper {
  width: calc(100% - 50px);
  position: absolute;
  padding: 15px;
  margin-right: 0;
  left: 50px;
  transition: all 0.5s ease;
}
/* Sidebar Styles */
.sidebar-nav {
  position: absolute;
  top: 80px;
  width: 200px;
  margin: 0;
  padding: 0;
  list-style: none;
  background: none;
}
.sidebar-nav li {
  text-indent: 0px;
}
.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #fff;
  padding: 15px 15px 15px 15px;
  letter-spacing: -0.25px;
  transition: all 0.2s ease;
}
.sidebar-nav li a i {
  margin-right: 20px;
  font-size: 20px;
  color: #fff;
  transition: all 0.2s ease;
}
.sidebar-nav li a i img {
  width: 20px;
  max-height: 20px;
  transition: all 0.2s ease;
}
.sidebar-nav li a span {
  font-size: 11px;
  font-weight: 500;
  transition: all 0.2s ease;
}
.sidebar-nav li a:hover, .sidebar-nav li a.active {
  text-decoration: none;
  color: #4181CF;
  background: #fff;
}
.sidebar-nav li a:hover span,
.sidebar-nav li a:hover i, .sidebar-nav li a.active span,
.sidebar-nav li a.active i {
  color: #4181CF;
}
.sidebar-nav li a:active, .sidebar-nav li a:focus {
  text-decoration: none;
}
.sidebar-nav > .sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
}
.sidebar-nav > .sidebar-brand a {
  color: #999999;
}
.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none;
}

/*Footer Styles*/
.footer-copyright {
  padding: 10px 25px;
  background: #c7c7c7;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.footer-copyright img {
  max-height: 30px;
  max-width: 100%;
  display: inline-block;
}

/* PLACEHOLDER */
.placeholder {
  clear: both;
  text-align: center;
  padding: 40px;
  min-height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.placeholder .placeholder-img {
  max-height: 200px;
  opacity: 1;
}
.placeholder .placeholder-title {
  font-size: 26px;
  font-weight: 100;
  color: #A4A4A4;
  margin: 30px 0;
}

.person-tbl tbody tr {
  background: transparent;
}
.person-tbl tbody tr:hover {
  background: transparent;
}
.person-tbl td {
  border-top: 0 !important;
  padding-left: 0;
  padding-right: 0;
}
.person-tbl .person-reports .report-col {
  width: 100%;
  /*pass feild*/
  /*Alert feild*/
  /*info fields*/
}
.person-tbl .person-reports .report-col .pr-item-descriptor {
  font-size: 26px;
  line-height: 32px;
}
.person-tbl .person-reports .report-col .pr-alert {
  font-size: 20px;
  line-height: 26px;
}
.person-tbl .person-reports .report-col .info-row .col:not(:last-of-type) {
  border-right: 1px solid lightgray;
}
.person-tbl .person-reports .report-col .info-row .info-lbl {
  text-align: center;
  margin-bottom: 10px;
  text-align: center;
}
.person-tbl .person-reports .report-col .info-row .lnr {
  margin: 10px !important;
  font-size: 55px !important;
}
.person-tbl .person-reports .report-col .info-row .info-status {
  /*margin-top: 10px;*/
  text-align: center;
  font-size: 12px;
}
.person-tbl .person-reports .report-col .pass-match {
  color: green;
}
.person-tbl .person-reports .report-col .pass-notmatch {
  color: orange;
}
.person-tbl .person-reports .report-col .pass-mismatch {
  color: #e6d600;
}
.person-tbl .person-reports .report-col .pass-notavailable {
  color: red;
}
.person-tbl .person-reports .report-col .alert-notmatch i,
.person-tbl .person-reports .report-col .alert-notmatch .info-status {
  color: gray;
}
.person-tbl .person-reports .report-col .alert-match i,
.person-tbl .person-reports .report-col .alert-match .info-status {
  color: red;
}
.person-tbl .person-reports .report-col .alert-notavailable i,
.person-tbl .person-reports .report-col .alert-notavailable .info-status {
  color: black;
}
.person-tbl .person-reports .report-col .info-match i,
.person-tbl .person-reports .report-col .info-match .info-status {
  color: green;
}
.person-tbl .person-reports .report-col .info-notmatch i,
.person-tbl .person-reports .report-col .info-notmatch .info-status {
  color: gray;
}
.person-tbl .person-reports .report-col .info-notavailable i,
.person-tbl .person-reports .report-col .info-notavailable .info-status {
  color: black;
}

.indicator {
  height: 25px;
  width: 25px;
  background-color: #e1e1e1;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  padding: 1px;
  margin: 2px;
}
.indicator.active {
  background-color: #0072a1;
  color: #fff;
}
.indicator.inactive {
  background-color: #e1e1e1;
}

.col-finance-eligibility-icons {
  min-width: 60px;
  width: 140px;
}

@media (min-width: 1200px) {
  .horiz-form > [class^=col]:last-child {
    border-left: 4px solid #dedede;
  }
  .invoice-dates,
.cash-values {
    min-width: 600px;
  }
  .card-invoice .card-body .invoice-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
  .card-invoice .card-body .invoice-inner > div:first-child {
    flex: 0.9 0;
  }
}
@media (max-width: 1199px) {
  .card-invoice .card-body {
    padding-top: 45px;
  }
  .card-invoice .invoice-balance {
    background: #efefef;
    padding: 10px;
    margin-top: 15px;
  }
  .navbar-toggler {
    width: 40px;
  }
  header .nav-user .user-name {
    font-size: 16px;
    line-height: 16px;
  }
  header#sub-header .rootnav .root-item.nav-item a {
    padding: 19px 13px 18px 13px;
    /*span {
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 500;
                }

                i {
                    font-size: 20px;
                }

                &:hover, &.active {
                    span {
                        font-weight: 500;
                    }
                }*/
  }
  .progress {
    margin-bottom: 20px;
  }
  .custom-search {
    margin-right: 0;
    margin-top: 10px;
    float: right;
    clear: both;
  }
}
@media (min-width: 992px) {
  .mobile-nav {
    display: none;
  }
  #menu-toggle {
    display: none;
  }
  #wrapper.toggled #sidebar-wrapper {
    width: 100px;
  }
  #sidebar-wrapper {
    width: 100px;
  }
  #page-content-wrapper {
    width: 100%;
    left: 0;
    padding: 40px 20px;
    position: relative;
  }
  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
  .sidebar-nav {
    overflow-y: hidden;
    top: 0px;
    width: 100px;
  }
  .sidebar-nav li a {
    padding: 20px 10px 20px 10px;
    font-size: 13px;
    text-align: center;
  }
  .sidebar-nav li a i {
    font-size: 25px;
    display: block;
    text-align: center;
    width: 100%;
  }
  .sidebar-nav li a i img {
    max-height: 25px;
    width: 25px;
  }
  .sidebar-nav li a span {
    font-size: 11px;
  }
  .inner-content {
    width: calc(100% - 250px);
    min-height: calc(100vh - 150px);
    margin-left: 255px;
    padding: 30px 30px;
    margin-top: 0px;
  }
  .inner-content .fixed-form-footer {
    left: 250px;
  }
  .subnav .nav-dropdown {
    display: none;
  }
}
@media (max-width: 991px) {
  #wrapper {
    min-height: calc(100vh - 115px);
  }
  .custom-tabs {
    display: block;
  }
  .custom-tbl th .col-email,
.custom-tbl td .col-email {
    max-width: 200px;
    word-break: break-word;
  }
  .inner-sidebar.subnav {
    background: none;
    height: auto;
    -o-box-shadow: none;
    box-shadow: none;
    padding: 0;
    border: none;
    left: auto;
    position: relative;
    z-index: 9;
    display: inline-block;
    width: 100%;
    float: left;
    margin-top: -20px;
    overflow: visible;
  }
  .inner-sidebar.subnav .nav-title,
.inner-sidebar.subnav .nav {
    display: none;
  }
  .inner-sidebar.subnav .nav-dropdown button.btn.btn-secondary {
    background: #fff;
    color: #333;
    border: 1px solid #dedede;
    padding: 5px 15px;
    min-width: 0;
    min-width: initial;
  }
  .inner-sidebar.subnav .nav-dropdown .dropdown-menu .dropdown-item a {
    color: #4181CF;
    font-size: 15px;
    font-weight: 600;
  }
  .inner-sidebar.subnav .nav-dropdown .dropdown-menu .dropdown-item:active, .inner-sidebar.subnav .nav-dropdown .dropdown-menu .dropdown-item.active {
    background: #4181CF;
  }
  .inner-sidebar.subnav .nav-dropdown .dropdown-menu .dropdown-item:active a, .inner-sidebar.subnav .nav-dropdown .dropdown-menu .dropdown-item.active a {
    color: #fff;
  }
  .inner-sidebar.subnav .nav-dropdown .dropdown-menu.show {
    left: -5px;
    top: 25px;
  }
  .inner-sidebar.subnav ~ .inner-content {
    display: inline-block;
    width: 100%;
    clear: both;
    margin-left: 0;
  }
  .inner-sidebar.subnav ~ .inner-content .custom-alert {
    margin-top: -105px;
    margin-bottom: 72px;
  }
  .inner-sidebar.subnav ~ .inner-content .custom-alert.show-alert {
    margin-top: -72px;
  }
  header .rootnav .root-item.nav-item {
    display: none;
  }
  header .rootnav .nav-user .nav-link {
    background: #efefef;
  }
  header .rootnav .nav-user .nav-link img {
    display: none;
  }
  header .rootnav .nav-user .dropdown-menu {
    display: block;
  }
  header .mobile-nav {
    display: block;
    position: fixed;
    top: 64px;
    left: -400px;
    transition: all 0.4s;
  }
  header .mobile-nav .nav {
    flex-direction: column;
  }
  header .mobile-nav .nav .nav-item {
    border-bottom: none;
  }
  header .mobile-nav .nav .nav-item > a {
    padding: 14px 20px 14px 20px;
  }
  header .mobile-nav .nav .nav-item > a span {
    font-size: 14px;
  }
  header .mobile-nav .nav .nav-item > a i {
    margin-right: 15px;
    font-size: 18px;
  }
  header .mobile-nav .nav .nav-item > a.active:after {
    display: none;
  }
  header .mobile-nav .nav .nav-item > a.root-item.parent {
    position: relative;
  }
  header .mobile-nav .nav .nav-item > a.root-item.parent:after {
    display: inline-block;
    content: "\e876";
    font-family: Linearicons-Free;
    speak: none;
    font-style: normal;
    font-weight: 900;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 11px;
    margin-left: 10px;
    position: absolute;
    right: 10px;
    top: 22px;
    transition: all 0.2s;
  }
  header .mobile-nav .nav .nav-item > a.root-item.parent.parent.active:after {
    transform: rotate(90deg);
  }
  header .mobile-nav .nav .nav-item div > a {
    padding: 12px 20px 12px 55px;
    position: relative;
    color: #333;
  }
  header .mobile-nav .nav .nav-item div > a:before {
    content: "";
    display: inline-block;
    height: 42px;
    width: 1px;
    position: absolute;
    background: #dedede;
    left: 30px;
    top: 0;
    transition: all 0.2s;
  }
  header .mobile-nav .nav .nav-item div > a.active {
    color: #000;
  }
  header .mobile-nav .nav .nav-item div > a.active:hover {
    color: #000;
  }
  header .mobile-nav .nav .nav-item div > a.active:before {
    width: 3px;
    left: 29px;
    background: #333;
  }
  header .mobile-nav .nav .nav-item div > a.active:after {
    display: none;
  }
  header.expanded .mobile-nav {
    left: 0px;
  }
  header.expanded #menu-toggle span {
    color: #4181CF;
  }
  header#global-header li.nav-user > a.nav-link {
    padding-left: 20px;
  }
  header#global-header li.nav-user > a.nav-link img {
    display: none;
  }
  header#global-header li.nav-user .dropdown-menu {
    display: block !important;
  }
  .custom-tabs {
    margin: 0;
  }
  #page-content-wrapper {
    left: 0;
    width: 100%;
    padding: 40px 10px;
    position: relative;
  }
  .inner-content {
    width: 100%;
    padding: 0;
  }
  .inner-content .wr-card-items.wr-reports .card-report .list-group {
    border-left: none;
    padding: 20px;
  }
  .integration-selector .card-community img {
    padding-left: 15px;
  }
  .integration-selector .card-community h2 {
    font-size: 14px;
  }
  .integration-selector .card-community p {
    font-size: 10px;
  }
}
.add_file_type .custom-form-control {
  padding: 5px;
}
.add_file_type .custom-form-control .btn-upload {
  font-weight: 300;
}
.add_file_type .custom-form-control span {
  margin-left: 15px;
}
.add_file_type .div-action span {
  padding: 12px;
}
.add_file_type .div-action span i {
  color: #dd0000;
  font-weight: 700;
  cursor: pointer;
}

@media screen and (min-width: 820px) {
  .card-onboarding {
    position: relative;
    display: flex;
    flex-direction: row;
  }
  .card-onboarding .onboarding-left {
    text-align: left;
    padding: 60px 0px 60px 80px;
    flex-basis: calc(50% - 130px);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .card-onboarding .onboarding-left:after {
    background: #003169;
    position: absolute;
    content: " ";
    display: block;
    top: -10%;
    bottom: -10%;
    right: -75px;
    transform: rotate(10deg);
    width: 400px;
    height: 150%;
    z-index: 1;
  }
  .card-onboarding .onboarding-left h1 {
    font-size: 40px;
    margin-bottom: 30px;
    position: relative;
    z-index: 2;
  }
  .card-onboarding .onboarding-left p {
    font-size: 16px;
    position: relative;
    z-index: 2;
  }
  .card-onboarding .onboarding-right {
    padding: 60px 80px 60px 180px;
    flex-basis: calc(50% + 130px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .card-onboarding .onboarding-right h2 {
    font-size: 24px;
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) {
  .uppy-Dashboard .uppy-Dashboard-inner {
    height: 350px;
  }
  .card-invoice .wr-status {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
  }
  .card-invoice .wr-status .badge {
    width: auto;
  }
  .card-invoice .finance-status {
    border-radius: 0 0 7px 7px;
    padding: 5px 40px;
    margin-right: 15px;
  }
  .card-invoice .invoice-status {
    border-radius: 0 7px 0 7px;
    padding: 5px 40px;
  }
}
@media (max-width: 767px) {
  .card-onboarding {
    display: block;
  }
  .wr-card-items.wr-reports .card-report .item-reference {
    max-width: 250px;
  }
  /*.custom-tbl{
        td{
            min-width: 150px;
        }
    }*/
}
.fade-in {
  -webkit-animation-name: reset, fade-in;
          animation-name: reset, fade-in;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

@-webkit-keyframes reset {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes reset {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 1000ms linear;
}

.fade-exit {
  opacity: 0;
  transform: translateY(-40px);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(-40px);
}

.fade-exit-done {
  opacity: 0;
  transform: translateY(-40px);
}

.fade-appear {
  opacity: 0;
  transform: translateY(-40px);
}

.fade-enter-done {
  opacity: 1;
  transform: translateY(0);
}

.__react_component_tooltip {
  text-transform: none;
  max-width: 60vw;
}

.xero-connect-btn {
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
}

.search-datepicker {
  clear: both;
}

.header-active-item {
  color: #4181CF !important;
}

.search-label {
  margin: 0;
}

.opacity-none {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.key-label {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #e4e4e4;
  font-weight: 700;
  padding-left: 13px;
  text-align: start;
  color: #696464;
  margin-bottom: 30px;
}

.key-label-items {
  padding-left: 15px;
}

.finance-tbl {
  width: auto !important;
}

.adjustment-amounts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.adjustment-amounts .adjustment-amount-text h3 {
  font-size: 16px;
  width: 350px;
  text-align: end;
  padding-right: 10px;
}
.adjustment-amounts .adjustment-amount-text h4 {
  font-size: 18px;
  width: 350px;
  font-weight: 600;
  text-align: end;
  padding: 10px;
}
.adjustment-amounts .adjustment-cash-amount h3 {
  font-size: 16px;
  text-align: end;
  font-weight: 600;
}
.adjustment-amounts .adjustment-cash-amount h4 {
  font-size: 28px;
  text-align: end;
  font-weight: 600;
}

.table-responsive .table-bordered tbody tr td:first-child {
  border-left: 0;
}
.table-responsive .table-bordered tbody tr td:last-child {
  border-right: 0;
}

.trace-report {
  max-height: 300px;
  margin-top: 10px;
  overflow-y: scroll;
}
.trace-report thead {
  background-color: #ffff99;
}
.trace-report thead tr:first-child {
  background-color: #ffbd5b;
}
.trace-report thead tr:first-child th {
  border: none;
}

.request-criteria {
  background-color: #ccc0d9 !important;
}

.row-highlighted {
  background-color: #ffbd5b !important;
}
.row-highlighted th {
  border: none !important;
}

.row-highlighted2 {
  background-color: #ffff99 !important;
}
.row-highlighted2 th {
  border: none !important;
}

.account-import td {
  vertical-align: top;
}

.dlt-row-cross .lnr-cross {
  color: #dd0000;
  font-weight: 700;
}

.typeahead-control .rbt-token-removeable {
  padding-right: 30px;
}
.typeahead-control .rbt-token-remove-button span {
  font-size: 22px;
}
.typeahead-control .rbt-close {
  color: #dd0000;
}

.add-invoice-header .add-invoice-header-left {
  padding: 3px;
}
.add-invoice-header .add-invoice-header-left .invoice-party-name {
  margin-bottom: -6px;
}
.add-invoice-header .add-invoice-header-left .invoice-party-name small h6 {
  font-weight: 800;
  color: #262626;
  text-transform: capitalize;
}
.add-invoice-header .add-invoice-header-left small {
  font-size: 12px;
  font-weight: 600;
  color: #646464;
  text-transform: uppercase;
}
.add-invoice-header .add-invoice-header-right .invoice-acn small {
  font-size: 12px;
  font-weight: 600;
  color: #646464;
}

.fianancial-summary-form-helptext {
  font-weight: 400;
  font-style: italic;
}

/*chart styles*/
.apexcharts-canvas {
  margin: 0 auto !important;
}

.speedometer {
  margin: 0 auto !important;
}

.linear-graph {
  width: 100%;
}

.sub-h {
  font-size: 14px;
  font-weight: 900;
  margin: 0;
  text-transform: uppercase;
}

.add-row-plus i {
  color: #4181cf;
  background-color: rgba(160, 198, 241, 0.11);
  padding: 5px;
  border-radius: 50%;
}

.rounded-lg {
  border-radius: 5px;
}

.custom-placeholder {
  min-height: 0;
  min-height: initial;
}

.ppsr-registration-span {
  font-weight: 800;
  font-size: 16px;
}

.collateral-details {
  margin-bottom: 5px;
}
.collateral-details .collateral-type {
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 2px;
}
.collateral-details .collateral-description {
  font-size: 14px;
}

.early-payment-txt {
  margin-left: auto;
  margin-right: 37px;
  font-size: 15px;
  margin-top: 20px;
  font-weight: bold;
  color: #777;
  text-transform: uppercase;
}

.help-center-heading h2 {
  color: white;
  text-align: center;
}
.help-center-heading h6 {
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.outer-content {
  background: #003169;
  min-height: 100vh;
  padding: 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.body-content {
  padding-left: 15px;
  padding-right: 15px;
}

.help-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #4181CF;
}
.help-list .btn-link {
  color: #4181CF;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.help-list .btn-link i {
  margin-top: 0;
}
.help-list .btn {
  padding: 0;
}
.help-list .vid-duration {
  display: flex;
  align-self: center;
  color: black;
}
.help-list h5 i {
  float: left;
  padding-right: 15px;
}

.help-video iframe {
  width: 100%;
  max-width: 560px;
  height: 315px;
}

.manage-sso-placeholder {
  min-height: 100px;
}

.doc-download {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: #4181CF !important;
  cursor: pointer;
}
.doc-download i {
  font-size: 16px;
  margin-right: 5px;
}
.doc-download span {
  text-decoration: underline;
}

.custom-tbl.wr-table .col-general.head-num {
  width: 350px;
}
.custom-tbl.wr-table .col-wrap {
  word-break: break-all;
}

.wr-draft-invoice-tbl {
  display: -ms-inline-grid;
  display: -moz-inline-grid;
  display: inline-grid;
}

/*# sourceMappingURL=style.css.map */

